import { useEffect, useRef, useState } from "react";
import {
  ComunityListsMenu,
  ListStepMenuBox,
  TestMeasureListBox,
  TestMeasureListContainer,
} from "style/mypage";
import { AnimatePresence, motion } from "framer-motion";
import SimpleTestListItem from "./simple.test.list.item";
import DeepTestListItem from "./deep.test.list.item";
import MeasureListItem from "./measure.list.item";
import {
  fetchLoadDeepTestResultList,
  fetchLoadSimpleTestResultList,
} from "api/mypage";
import { fetchLoadMeasureResultList } from "api/measure";
import { DataListProps } from "interface/interface.mypage";

const TestMeasureListsWrapper = () => {
  const [listStep, setListStep] = useState(0);
  const [data, setData] = useState<DataListProps>({
    simpleTestData: [],
    deepTestData: [],
    measureData: [],
  });

  console.log(data);
  console.log(data.deepTestData);
  console.log(data.simpleTestData);
  console.log(data.measureData);

  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // ✅ 더 가져올 데이터가 있는지 확인

  const observer = useRef<IntersectionObserver | null>(null);
  const lastElementRef = useRef<HTMLDivElement | null>(null); // 마지막 요소 감지

  const menuHandler = (idx: number) => {
    setListStep(idx);
    setPage(1); // ✅ 새로운 메뉴 클릭 시 페이지 초기화
    setHasMore(true);
  };

  useEffect(() => {
    const loadData = async (reset = false) => {
      if (isLoading || !hasMore) return;
      setIsLoading(true);

      const params = {
        orderBy: "REGDT",
        orderByDir: "DESC",
        pageSize: 10,
        offset: reset ? 0 : (page - 1) * 10, // ✅ reset이면 offset도 0으로 초기화
      };

      try {
        let response: any = { result: [] };
        if (listStep === 0)
          response = await fetchLoadSimpleTestResultList(params);
        else if (listStep === 1)
          response = await fetchLoadDeepTestResultList(params);
        else if (listStep === 2)
          response = await fetchLoadMeasureResultList(params);

        if (!response || !response.result) {
          setHasMore(false); // ✅ 응답이 없으면 데이터 없음 처리
          return;
        }

        if (response.result.length === 0) {
          setHasMore(false);
        } else {
          setData((prevData) => {
            if (listStep === 0) {
              return {
                ...prevData,
                simpleTestData: reset
                  ? response.result
                  : [...prevData.simpleTestData, ...response.result],
              };
            } else if (listStep === 1) {
              return {
                ...prevData,
                deepTestData: reset
                  ? response.result
                  : [...prevData.deepTestData, ...response.result],
              };
            } else {
              return {
                ...prevData,
                measureData: reset
                  ? response.result
                  : [...prevData.measureData, ...response.result],
              };
            }
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    loadData(true); // ✅ 초기 로딩 시 데이터 리셋
  }, [listStep]);

  // 무한 스크롤 감지 (Intersection Observer)
  useEffect(() => {
    if (isLoading || !hasMore) return;

    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 1.0 }
    );

    if (lastElementRef.current) {
      observer.current.observe(lastElementRef.current);
    }

    return () => observer.current?.disconnect();
  }, [isLoading, hasMore]);

  return (
    <TestMeasureListContainer>
      {/* 메뉴 */}
      <div style={{ padding: "1rem", backgroundColor: "#fafafa" }}></div>
      <ComunityListsMenu>
        {["간이검사", "심화검사", "측정"].map((listMenu, index) => (
          <ListStepMenuBox
            key={index}
            onClick={() => menuHandler(index)}
            className={`menu-item ${listStep === index ? "isActive" : ""}`}
            layout
            transition={{ type: "spring", stiffness: 300, damping: 20 }}
          >
            <motion.span transition={{ duration: 0.3 }}>{listMenu}</motion.span>

            {listStep === index && (
              <motion.div
                layoutId="listUnderline_test_measure"
                className="underline"
                transition={{ type: "spring", stiffness: 300, damping: 20 }}
              />
            )}
          </ListStepMenuBox>
        ))}
      </ComunityListsMenu>

      {/* 리스트 */}
      <TestMeasureListBox>
        <AnimatePresence mode="wait" initial={false}>
          {listStep === 0 && (
            <motion.div
              key={0}
              initial={{ opacity: 0, y: 20 }} // 초기 상태 (살짝 오른쪽에서 시작)
              animate={{ opacity: 1, y: 0 }} // 나타날 때
              exit={{ opacity: 0, y: -20 }} // 사라질 때 (왼쪽으로 이동하며 사라짐)
              transition={{ duration: 0.3 }} // 애니메이션 속도
              style={{ height: "100%" }}
            >
              <SimpleTestListItem data={data.simpleTestData} />
            </motion.div>
          )}
          {listStep === 1 && (
            <motion.div
              key={1}
              initial={{ opacity: 0, y: 20 }} // 초기 상태 (살짝 오른쪽에서 시작)
              animate={{ opacity: 1, y: 0 }} // 나타날 때
              exit={{ opacity: 0, y: -20 }} // 사라질 때 (왼쪽으로 이동하며 사라짐)
              transition={{ duration: 0.3 }} // 애니메이션 속도
              style={{ height: "100%" }}
            >
              {" "}
              <DeepTestListItem data={data.deepTestData} />
            </motion.div>
          )}
          {listStep === 2 && (
            <motion.div
              key={2}
              initial={{ opacity: 0, y: 20 }} // 초기 상태 (살짝 오른쪽에서 시작)
              animate={{ opacity: 1, y: 0 }} // 나타날 때
              exit={{ opacity: 0, y: -20 }} // 사라질 때 (왼쪽으로 이동하며 사라짐)
              transition={{ duration: 0.3 }} // 애니메이션 속도
              style={{ height: "100%" }}
            >
              {" "}
              <MeasureListItem data={data.measureData} />
            </motion.div>
          )}
        </AnimatePresence>

        {/* 무한 스크롤 트리거 */}
        {hasMore && !isLoading && (
          <div
            ref={lastElementRef}
            style={{ height: "10px", backgroundColor: "#fafafa" }}
          />
        )}
      </TestMeasureListBox>
    </TestMeasureListContainer>
  );
};

export default TestMeasureListsWrapper;
