import { ReviewQueryParams } from "interface/interface.review";
import axiosInstance from "../plugin/axios";
import axios from "axios";

// 후기 조회
export const reviewListHandler = async (params: ReviewQueryParams) => {
  try {
    const response = await axiosInstance.get(`/review`, { params });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

// 후기 등록
export const reviewRegistHandler = async (data: FormData) => {
  try {
    const response = await axiosInstance.post("/review", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json;charset=UTF-8",
      },
    });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

// 후기 수정
export const reviewModifyHandler = async (
  reviewId: number,
  data: { title: string; contents: string }
) => {
  try {
    const response = await axiosInstance.put(`/review/${reviewId}`, data);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

// 후기 삭제
export const reveiewDeleteHandler = async (reviewId: number) => {
  try {
    const response = await axiosInstance.delete(`/review/${reviewId}`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

// 후기 상세 조회
export const reviewDetailHandler = async (id: number) => {
  try {
    const response = await axiosInstance.get(`/review/${id}`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

// 후기 이미지 조회 상세 조회와 통합되어 안씀
export const reviewDetailImgFileSearchHandler = async (fileGroupId: string) => {
  try {
    const response = await axiosInstance.get("/file", {
      params: {
        fileGroupId: fileGroupId,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error();
  }
};

// 후기 조회
export const reviewViewCountHandler = async (id: number) => {
  try {
    const response = await axiosInstance.put(`/review/${id}/views`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

// 후기 댓글 조회
export const reviewCommentSearchHandler = async (id: number) => {
  try {
    const response = await axiosInstance.get(`/review/${id}/comment`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

// 후기 댓글 등록
export const reviewCommentRegist = async (data: {
  reviewId: number;
  contents: string;
  parentCommentId: number | null;
}) => {
  try {
    const response = await axiosInstance.post(`/review/comment`, data, {
      headers: {
        "Content-Type": "application/json", // JSON 형식
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to register comment:", error);
    throw error;
  }
};

// 후기 댓글 수정
export const reviewCommentModify = async (
  commentId: number,
  contents: string
) => {
  try {
    const response = await axiosInstance.put(`/review/comment/${commentId}`, {
      contents,
    });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

// 후기 댓글 삭제
export const reviewCommentDelete = async (commentId: number) => {
  try {
    const response = await axiosInstance.delete(`/review/comment/${commentId}`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

// 후기 좋아요
export const reviewLikeUpHandler = async (reviewId: number) => {
  try {
    const response = await axiosInstance.post(`/review/like`, null, {
      params: { reviewId },
    });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

export const reviewLikeDownHandler = async (reviewId: number) => {
  try {
    const response = await axiosInstance.delete(`/review/like/${reviewId}`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

// 차단 조회
export const reviewBlockHandler = async () => {
  try {
    const response = await axiosInstance.get(`/block`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

// 차단 등록
export const reviewBlockEnrollHandler = async (blockUserId: string) => {
  try {
    const response = await axiosInstance.post(`/block`, null, {
      params: {
        blockUserId,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

// 차단 해제
export const reviewBlockCancelHandler = async (blockUserId: string) => {
  try {
    const response = await axiosInstance.delete(`/block`, {
      params: { blockUserId },
    });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

// 신고
export const reviewDeclararionHandler = async ({
  type,
  targetId,
  reason,
}: {
  type: string;
  targetId: number;
  reason: string;
}) => {
  try {
    const requstData = {
      type: type,
      targetId: targetId,
      reason: reason,
    };

    const response = await axiosInstance.post(`/report`, requstData);

    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.status === 409) {
        // ✅ 409 상태 코드 감지 → 중복 신고 처리
        throw new Error("409");
      } else {
        throw new Error(`서버 오류 발생: ${error.response.status}`);
      }
    } else {
      throw new Error("네트워크 오류 또는 서버 응답 없음");
    }
  }
};

// preview
// review list
export const fetchPreviewReviewList = async (params: ReviewQueryParams) => {
  try {
    const response = await axiosInstance.get(`/preview/review`, { params });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

// 후기 상세 조회
export const fetchPreviewReviewDetail = async (id: number) => {
  try {
    const response = await axiosInstance.get(`/preview/review/${id}`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

// 후기 댓글 조회
export const fetchPreviewReviewComment = async (id: number) => {
  try {
    const response = await axiosInstance.get(`/preview/review/${id}/comment`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

// 후기 조회 count
export const fetchPreviewReviewCount = async (id: number) => {
  try {
    const response = await axiosInstance.put(`/preview/${id}/views`);
    return response.data;
  } catch (error) {
    throw new Error();
  }
};
