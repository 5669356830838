import { HeartIconX14, RepleIcon } from "cssReset/icon";
import { useNavigate } from "react-router-dom";
import { ListItemBox, NoDataBoxCenter } from "style/mypage";
import { formatDateTime } from "utils/util";

interface DataProps {
  data: any;
}

const ComunityListItem = ({ data }: DataProps) => {
  const navigate = useNavigate();

  // 후기 상세 페이지 이동
  const reviewDetailEnter = (id: string) => {
    navigate(`/review/detail/${id}`);
  };
  return (
    <ListItemBox>
      {data.length > 0 ? (
        data.map((item: any, index: number) => (
          <div
            key={`${item.reviewId}-${index}`} // ✅ reviewId + index로 고유한 key 생성
            className="review-item"
            onClick={() => reviewDetailEnter(String(item.reviewId))}
          >
            <div className="l-box">
              <p className="title">{item.title}</p>
              <p className="contents">{item.contents}</p>
              <div className="profile-box">
                <div className="profile-img-box">
                  <img
                    src={
                      item.userProfileUrl
                        ? item.userProfileUrl
                        : "/assets/icon/userprofile.png"
                    }
                    alt="profile"
                  />
                </div>
                <span className="username">{item.userName}</span>
              </div>
              <div className="sub-items">
                <span className="date">{formatDateTime(item.regDt)}</span>
                <span className="like-count">
                  <span className="icon-box">
                    <HeartIconX14 />
                  </span>
                  {item.reviewLikeCount}
                </span>
                <span className="comment-count">
                  <span className="icon-box">
                    <RepleIcon />
                  </span>
                  {item.reviewCommentCount}
                </span>
              </div>
            </div>
            {item.files.length > 0 && (
              <div className="r-box">
                <div className="img-box">
                  <img
                    src={item.files?.[0]?.accessUrl}
                    alt={item.files?.[0]?.realFileName}
                  />
                </div>
                {item.files.length > 1 && (
                  <div className="img-count-box">+ {item.files.length} </div>
                )}
              </div>
            )}
          </div>
        ))
      ) : (
        <NoDataBoxCenter>
          <div className="title">
            <p className="no-data">작성한 글이 없습니다.</p>
          </div>
        </NoDataBoxCenter>
      )}
    </ListItemBox>
  );
};

export default ComunityListItem;
