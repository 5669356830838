import { haederLoginModalState } from "atoms/layout.state";
import LoginModal from "components/auth/login/modal/login.modal";
import ModalCenterLayout from "components/modal/modal.center.layout";
import { MainBellIcon, MainSettingIcon } from "cssReset/icon";
import { HeaderTitleProps } from "interface/interface.header";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { closeModal } from "utils/modal.util";

const Header = ({ headerTitle }: HeaderTitleProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loginModal, setLoginModal] = useRecoilState(haederLoginModalState);
  const accessToken = localStorage.getItem("accessToken");

  const clickedHandler = (path: string) => {
    if (accessToken) {
      navigate(path);
    } else {
      setLoginModal({ isOpen: true, isVisible: true });
    }
  };

  const loginModalClosed = () => {
    closeModal(setLoginModal);
  };

  return (
    <HeaderContainer
      className={location.pathname === "/mypage" ? "isMypageActive" : ""}
    >
      <div className="home-title">
        <h1 className={location.pathname === "/home" ? "monoton" : ""}>
          {headerTitle}
        </h1>
      </div>
      {location.pathname === "/home" ? (
        <>
          <div
            className="icon-box"
            onClick={() => {
              clickedHandler("/alram");
            }}
          >
            <MainBellIcon />
          </div>
          <div
            className="icon-box"
            onClick={() => {
              clickedHandler("/setting");
            }}
          >
            <MainSettingIcon />
          </div>
        </>
      ) : location.pathname === "/mypage" ? (
        <div
          className="icon-box"
          onClick={() => {
            clickedHandler("/setting");
          }}
        >
          <MainSettingIcon />
        </div>
      ) : (
        ""
      )}

      {loginModal && (
        <ModalCenterLayout
          closedHandler={loginModalClosed}
          isVisible={loginModal.isVisible}
        >
          <LoginModal closedHandler={loginModalClosed} />
        </ModalCenterLayout>
      )}
    </HeaderContainer>
  );
};

export default Header;

const HeaderContainer = styled.header`
  height: 5.6rem;
  display: grid;
  grid-template-columns: auto 4.8rem 4.8rem;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  z-index: 99;
  .home-title {
    padding: 1.8rem 1.6rem;
    h1.monoton {
      font-family: "Monoton";
      font-weight: 100;
      font-size: 2rem;
      line-height: 2rem;
      transform: translateY(3px);
    }
  }
  .icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.isMypageActive {
    grid-template-columns: auto 4.8rem;
  }
`;
