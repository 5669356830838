import { NoDataBox, NoDataCard, TestMeasureListItemBox } from "style/mypage";

interface ListDataProps {
  data: any[];
}

const DeepTestListItem = ({ data }: ListDataProps) => {
  return (
    <TestMeasureListItemBox>
      {data && data.length !== 0 ? (
        <div>
          {data.map((item, index) => (
            <div>test</div>
          ))}
        </div>
      ) : (
        <NoDataBox>
          <div className="title">
            <p>층간소음 유형 심화검사</p>
            <span>검사 정보 없음</span>
          </div>
          <NoDataCard>
            <p>
              검사 결과가 없습니다. <br />
              나의 기질과 청력 검사를 통해, <br />
              층간소음에 얼마나 취약할지 알아보세요.
            </p>
            <button>나의 층간소음 취약도 알아보기</button>
          </NoDataCard>
        </NoDataBox>
      )}
    </TestMeasureListItemBox>
  );
};

export default DeepTestListItem;
