import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { motion } from "framer-motion"; // Framer Motion import
import { FooterMenu } from "utils/datas";

const Footer = () => {
  const location = useLocation();
  return (
    <FooterWrapper>
      <ul>
        {FooterMenu.map((item, index) => {
          const extraPathsMap: { [key: string]: string[] } = {
            "/measure": ["/measure/simple/recording/result/list"],
            // 필요 시 다른 path에도 추가 가능
          };

          const activePaths = [item.path, ...(extraPathsMap[item.path] || [])];
          const isActive = activePaths.includes(location.pathname);
          return (
            <motion.li
              key={index}
              className={isActive ? "active" : ""}
              layoutId={`footer-item-${index}`} // 🔥 각 아이템마다 고유한 layoutId 부여
              transition={{ duration: 0.3 }} // 색상 전환 애니메이션 속도
              layout
            >
              <Link to={item.path}>
                <div className="icon-box">
                  <item.icon />
                </div>
                <span
                  style={{
                    color: isActive
                      ? "var(--active-color)" // 활성화 색상
                      : "var(--non-active-color)", // 비활성화 색상
                  }}
                >
                  {item.title}
                </span>
              </Link>
            </motion.li>
          );
        })}
      </ul>
    </FooterWrapper>
  );
};

export default Footer;

// 스타일 정의
const FooterWrapper = styled.footer`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 0.9rem 0 2.1rem;
  background-color: white;
  border-top: 1px solid #cecece;
  ul {
    display: flex;
    justify-content: space-between;
    width: 100%;
    li {
      flex: 1;
      a {
        display: flex;
        gap: 0.2rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        .icon-box {
          display: flex;
        }
        span {
          font-size: 1.4rem;
          font-weight: 500;
          line-height: 1.8rem;
          transition: color 0.3s ease; /* 색상 전환 부드럽게 */
        }
      }
      &.active {
        a {
          .icon-box {
            svg {
              path {
                fill: ${(props) => props.theme.activeColor};
                transition: fill 0.3s ease; /* 아이콘 색상 부드럽게 */
              }
            }
          }
          span {
            color: ${(props) => props.theme.activeColor};
          }
        }
      }
    }
  }

  @media screen and (min-width: 785px) {
    width: 37.3rem;
    position: sticky;
  }
`;
