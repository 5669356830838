import { fetchLoadMeasureResultList } from "api/measure";
import MeasureHomeRecentlyRecord from "components/measure/components/measure.home.recently.record";
import { DoubleArrowIcon, RightArrowIconX16 } from "cssReset/icon";
import {
  MeasureParams,
  SimpleMeasureResult,
} from "interface/interface.measure";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NotLoginBox } from "style/login";
import { Container } from "style/measure";
import {} from "utils/util";

const MeasureHome = () => {
  const [data, setData] = useState<SimpleMeasureResult[]>([]);
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    const authStateRaw = localStorage.getItem("authState");

    if (!authStateRaw) return; // ✅ 아예 없으면 로그인 안 된 상태
    const auth = JSON.parse(authStateRaw);

    if (!auth?.isAuthenticated) return; // ✅ 로그인 되어 있지 않으면 중단

    const loadMeasureResultList = async () => {
      try {
        const params: MeasureParams = {
          orderBy: "REGDT",
          orderByDir: "DESC",
          pageSize: 5,
        };
        const response = await fetchLoadMeasureResultList(params);
        if (response.result) setData(response.result);
      } catch (error) {
        console.error(error);
      }
    };

    loadMeasureResultList();
  }, []);

  const simpleMeasureClicked = () => {
    const authStateRaw = localStorage.getItem("authState");

    // 로그인 안된 경우 → 비로그인용 경로로 이동
    if (!authStateRaw) {
      navigate("/measure/unauthenticated/step");
      return;
    }

    try {
      const auth = JSON.parse(authStateRaw);
      if (!auth?.isAuthenticated) {
        navigate("/measure/unauthenticated/step");
        return;
      }
    } catch (e) {
      console.warn("authState 파싱 오류:", e);
      navigate("/measure/unauthenticated/step");
      return;
    }

    // 로그인 상태 → 정상 경로로 이동
    navigate("/measure/simple/step");
  };

  return (
    <Container>
      <div className="simple-measure-box" onClick={simpleMeasureClicked}>
        <div className="l">
          <span>층간소음 간이 측정</span>
          <p>
            우리집 소음을 스마트폰으로 <br />
            간단히 측정해보세요.
          </p>
        </div>
        <div className="r">
          <DoubleArrowIcon />
        </div>
      </div>
      <div className="deep-measure-box">
        <div className="l">
          <span>층간소음 전문 측정 (키트 구입 필요)</span>
          <p>
            층간소음 전용 측정 키트로 <br />
            충격 소음까지 꼼꼼하게 잡아내세요.
          </p>
        </div>
        <div className="r">
          <DoubleArrowIcon />
        </div>
      </div>
      {accessToken ? (
        <div className="recently-record-box">
          <div className="t">
            <span>최근 측정 기록</span>
            <button
              onClick={() => {
                navigate("/measure/simple/recording/result/list");
              }}
            >
              전체 보기{" "}
              <span>
                <RightArrowIconX16 />
              </span>
            </button>
          </div>
          <div className="record">
            <MeasureHomeRecentlyRecord data={data} />
          </div>
        </div>
      ) : (
        <NotLoginBox>
          <p>
            지금 솜방망이에 로그인하고, <br />
            소음 측정 및 녹음 결과를 저장해보세요! <br />
            <br />
            로그인하지 않는 경우, 소음 녹음은 진행되지 않으며 <br />
            측정 기록을 저장할 수 없습니다.
          </p>
          <button onClick={() => navigate("/")}>로그인 하기</button>
        </NotLoginBox>
      )}
    </Container>
  );
};

export default MeasureHome;
