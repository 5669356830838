import { fetchPreviewReviewDetail } from "api/review";
import { LinkIcon } from "cssReset/icon";
import { ReviewDetailProps } from "interface/interface.review";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

const ModalShareBox = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [data, setData] = useState<ReviewDetailProps | null>(null);

  useEffect(() => {
    const loadPreviewDetail = async () => {
      try {
        if (!id) return; // id가 없으면 함수 실행 중단
        const response = await fetchPreviewReviewDetail(Number(id));

        if (!response?.result) {
          // id가 유효하지 않으면 404 페이지로 이동
          navigate("/404");
          return;
        }

        setData(response.result);
      } catch (error) {
        console.log(error);
      }
    };

    loadPreviewDetail();
  }, [id]);

  const shareToKakao = () => {
    if (data !== null) {
      Kakao.Share.sendCustom({
        templateId: 119524,
        templateArgs: {
          title: data.title,
          contents: data.contents,
          THU: "https://sombat.co.kr" + data.files[0].accessUrl,
          like: data.reviewLikeCount,
          comment: data.reviewCommentCount,
          view: data.views,

          url: `/review/detail/${data.reviewId}`,
        },
      });
    }
  };

  return (
    <BoxInner>
      <ShareItemBox>
        <li onClick={shareToKakao}>
          <span className="icon-box">
            <img src="/assets/icon/SNS/kakaotalk/Default.png" alt="" />
          </span>
          카카오톡 공유하기
        </li>
        <li>
          <span className="icon-box">
            <img
              src="/assets/icon/SNS/Facebook/Facebook - Original.png"
              alt=""
            />
          </span>
          페이스북 공유하기
        </li>
        <li>
          <span className="icon-box">
            <LinkIcon />
          </span>
          공유링크 복사하기
        </li>
      </ShareItemBox>
    </BoxInner>
  );
};

export default ModalShareBox;

const BoxInner = styled.div``;

const ShareItemBox = styled.ul`
  li {
    display: flex;
    padding: 1.6rem 1rem;
    gap: 1rem;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: -0.06rem;
    border-bottom: 1px solid #ececec;
    .icon-box {
      display: flex;
      width: 2.4rem;
      height: 2.4rem;
      img,
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
`;
