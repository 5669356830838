import { previewHeaderLoginModalState } from "atoms/layout.state";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { BoxInner, TextBox } from "style/modal";

interface ModalProps {
  closedHandler: () => void;
}

const LoginModal = ({ closedHandler }: ModalProps) => {
  const setLoginModal = useSetRecoilState(previewHeaderLoginModalState);
  const navigate = useNavigate();
  return (
    <BoxInner>
      <TextBox>
        <span>{`로그인하고 솜방망이의 \n모든 기능을 사용해보세요!`}</span>
      </TextBox>
      <div className="login-return-btn-box">
        <button
          className="continue"
          onClick={() => {
            setLoginModal({ isOpen: false, isVisible: false });
            navigate("/");
          }}
        >
          로그인 하기
        </button>
        <button className="del" onClick={closedHandler}>
          이전 화면으로 돌아가기
        </button>
      </div>
    </BoxInner>
  );
};

export default LoginModal;
