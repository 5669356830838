import { color } from "./../../node_modules/@types/d3-color/index.d";
import styled from "styled-components";
import { motion } from "framer-motion";

export const MypageWrap = styled.div``;

export const StepWarpper = styled.div``;

export const Step = styled(motion.div)``;

export const StepMenuWrapper = styled.div`
  display: flex;
  gap: 1.6rem;
  border-bottom: 1px solid #ececec;
  padding-top: 1.2rem;
`;

export const StepMenuBox = styled(motion.div)`
  width: 100%;
  padding: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: -0.06rem;
  color: #a1a1a1;
  position: relative;
  &.isActive {
    color: black;
  }

  /* ✅ 애니메이션으로 이동할 밑줄 */
  .underline {
    position: absolute;
    bottom: -1px;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: black;
    border-radius: 2px;
  }
`;

export const ComunityListsContainer = styled.div``;

export const ProfileBox = styled.div`
  display: flex;
  padding: 1.6rem;
  gap: 0.8rem;
  align-items: center;
  background-color: #fafafa;
  .img-box {
    width: 4rem;
    height: 4rem;
    display: flex;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10rem;
    }
  }
  .user-name {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.8rem;
    letter-spacing: -0.06rem;
  }
`;

export const ComunityListsMenu = styled.div`
  display: flex;
  padding: 0 1.6rem;
  background-color: #fafafa;
`;

export const ComunityListsBox = styled.div``;

export const ListStepMenuBox = styled(motion.div)`
  width: 100%;
  padding: 0.8rem 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: -0.06rem;
  color: #a1a1a1;
  position: relative;
  &.isActive {
    color: black;
  }

  /* ✅ 애니메이션으로 이동할 밑줄 */
  .underline {
    position: absolute;
    bottom: -1px;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: black;
    border-radius: 2px;
  }
`;

export const ListStep = styled(motion.div)``;

export const ListItemBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding: 1.6rem;
  .review-item {
    padding: 0.8rem 0;
    display: flex;
    gap: 0.8rem;
    border-bottom: 1px solid #ececec;
    .l-box {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
      .title {
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.8rem;
        letter-spacing: -0.06rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; /* 말줄임 적용 */
        width: 20.4rem;
      }
      .contents {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        width: 20.4rem;
      }
      .profile-box {
        display: flex;
        align-items: center;
        gap: 0.4rem;
        .username {
          font-size: 1rem;
          font-weight: 700;
          line-height: 1.4rem;
        }
        .profile-img-box {
          width: 2rem;
          height: 2rem;
          border-radius: 100px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .sub-items {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.2rem;
        > span {
          display: flex;
          align-items: center;
          gap: 0.2rem;
          color: #a1a1a1;
          .icon-box {
            display: flex;
          }
        }
      }
    }
    .r-box {
      width: 7.2rem;
      height: 7.2rem;
      overflow: hidden;
      position: relative;
      border-radius: 1rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .img-count-box {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 0.4rem 0.8rem;
        color: white;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.6rem;
      }
    }
  }
`;

export const TestMeasureListContainer = styled.div``;

export const TestMeasureListBox = styled.div`
  height: calc(100vh - 22rem);
  overflow: hidden scroll;
`;

export const TestMeasureListItemBox = styled.div`
  background-color: #fafafa;
  height: 100%;
  overflow: hidden scroll;
`;

export const NoDataBox = styled.div`
  padding: 2.4rem 1.6rem;
  .title {
    padding: 1.4rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 1.8rem;
      letter-spacing: -0.06rem;
    }
    span {
      font-size: 1.1rem;
      color: #575757;
      line-height: 1.4rem;
    }
  }
`;

export const NoDataCard = styled.div`
  background-color: white;
  border-radius: 1rem;
  padding: 2.4rem 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  box-shadow: 0px 0px 3px 0px #00000040;

  p {
    font-size: 1.2rem;
    line-height: 1.8rem;
    padding: 1.6rem;
    text-align: center;
  }
  button {
    padding: 1.5rem 2.3rem;
    border: 1px solid #225298;
    color: #225298;
    background-color: white;
    outline: none;
    border-radius: 0.5rem;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 2.2rem;
  }
`;

export const MeasureResultBox = styled.div`
  padding: 2.4rem 1.6rem;
  .title {
    padding: 1.4rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 1.8rem;
      letter-spacing: -0.06rem;
    }
    span {
      font-size: 1.1rem;
      color: #575757;
      line-height: 1.4rem;
    }
  }
`;

export const MeasureResultCard = styled.div`
  background-color: white;
  padding: 2.4rem 1.6rem;
  border-radius: 1rem;
  box-shadow: 0px 0px 3px 0px #00000040;
  ul {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    li {
      border: 1px solid #ececec;
      padding: 0.8rem 1.6rem;
      border-radius: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .name {
        display: flex;
        flex-direction: column;
        gap: 0.7rem;
        strong {
          font-size: 1.2rem;
          line-height: 1.6rem;
          letter-spacing: -0.06rem;
        }
        span {
          font-size: 1.1rem;
        }
      }
      .value {
        div {
          font-size: 1.2rem;
          font-weight: 700;
          line-height: 1.6rem;
          padding: 1.1rem 1.6rem;
          background-color: #edf6f0;
          color: #56b9a8;
          border-radius: 1rem;
          &.warning {
            background-color: #fcefed;
            color: #e37168;
          }
        }
      }
      &.last-item {
        gap: 1.2rem;
        padding: 1.6rem;
        border: none;
        background-color: #ececec;
        .name {
          strong {
            text-align: center;
          }
        }
        .hr {
          height: 3rem;
          width: 1px;
          background-color: #000000;
        }
        .last-value {
          font-size: 1.2rem;
          line-height: 1.6rem;
          strong {
            font-size: inherit;
          }
          span {
            font-size: inherit;
          }
          .sub-name {
            min-width: 6rem;
            display: inline-flex;
          }
        }
      }
    }
  }
`;

export const SubItemWrapper = styled.div`
  padding: 1.8rem 1.6rem 1rem;
  display: flex;
  align-items: center;

  .detail-veiw-btn {
    flex: 1;
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 2rem;
  }
  > span {
    width: 1px;
    height: 2rem;
    background-color: black;
  }
  .rotate {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    line-height: 2rem;
    font-weight: 500;
    .icon-box {
      display: flex;
    }
  }
`;

export const NoDataBoxCenter = styled.div`
  padding: 2.4rem 1.6rem;
  .title {
    padding: 1.4rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 1.8rem;
      letter-spacing: -0.06rem;
    }
    span {
      font-size: 1.1rem;
      color: #575757;
      line-height: 1.4rem;
    }
  }
`;
