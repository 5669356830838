import { fetchProfileInfo } from "api/profile";
import { isHomePathState } from "atoms/layout.state";
import { mypageLoginModalState } from "atoms/modal.state";
import LoginModal from "components/auth/login/modal/login.modal";
import ModalCenterLayout from "components/modal/modal.center.layout";
import ListsWrapper from "components/mypage/comunity/lists.wrapper";
import TestMeasureListsWrapper from "components/mypage/measure/lists.wrapper";
import { AnimatePresence, motion } from "framer-motion";
import { UserInfoDt } from "interface/interface.profile";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  MypageWrap,
  Step,
  StepMenuBox,
  StepMenuWrapper,
  StepWarpper,
} from "style/mypage";
import { closeModal } from "utils/modal.util";

const MypageHome = () => {
  const [step, setStep] = useState(0);
  const [profileDt, setProfileDt] = useState<UserInfoDt>();
  const setIsHomePath = useSetRecoilState(isHomePathState);
  const [loginModal, setLoginModal] = useRecoilState(mypageLoginModalState);
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();

  const menuHandler = (idx: number) => {
    setStep(idx);
  };

  useEffect(() => {
    const loadProfileInfo = async () => {
      try {
        const response = await fetchProfileInfo();
        setProfileDt(response.result);
      } catch (error) {
        console.log(error);
      }
    };

    loadProfileInfo();
  }, []);

  useEffect(() => {
    setIsHomePath("mypage");
  }, []);

  useEffect(() => {
    if (!accessToken) {
      setTimeout(() => {
        setLoginModal({ isOpen: true, isVisible: true });
      }, 300);
    }
  }, [accessToken]);

  const loginModalClosed = () => {
    closeModal(setLoginModal);
    navigate("/home");
  };

  return (
    <MypageWrap>
      <StepMenuWrapper>
        {["검사/측정", "커뮤니티"].map((menu, index) => (
          <StepMenuBox
            key={index}
            onClick={() => menuHandler(index)}
            className={step === index ? "isActive" : ""}
            layout // ✅ 애니메이션 활성화
            transition={{ type: "spring", stiffness: 300, damping: 20 }}
          >
            <motion.span transition={{ duration: 0.3 }}>{menu}</motion.span>

            {/* ✅ 밑줄 애니메이션 적용 */}
            {step === index && (
              <motion.div
                layoutId="underline"
                className="underline"
                transition={{ type: "spring", stiffness: 300, damping: 20 }}
              />
            )}
          </StepMenuBox>
        ))}
      </StepMenuWrapper>

      <StepWarpper>
        <AnimatePresence mode="wait" initial={false}>
          {step === 0 && (
            <Step
              key={0}
              initial={{ opacity: 0, y: 20 }} // 초기 상태 (살짝 오른쪽에서 시작)
              animate={{ opacity: 1, y: 0 }} // 나타날 때
              exit={{ opacity: 0, y: -20 }} // 사라질 때 (왼쪽으로 이동하며 사라짐)
              transition={{ duration: 0.3 }} // 애니메이션 속도
            >
              <TestMeasureListsWrapper />
            </Step>
          )}
          {step === 1 && (
            <Step
              key={1}
              initial={{ opacity: 0, y: 20 }} // 초기 상태 (살짝 오른쪽에서 시작)
              animate={{ opacity: 1, y: 0 }} // 나타날 때
              exit={{ opacity: 0, y: -20 }} // 사라질 때 (왼쪽으로 이동하며 사라짐)
              transition={{ duration: 0.3 }} // 애니메이션 속도
            >
              {profileDt && <ListsWrapper profileDt={profileDt} />}
            </Step>
          )}
        </AnimatePresence>
      </StepWarpper>

      {loginModal && (
        <ModalCenterLayout
          closedHandler={loginModalClosed}
          isVisible={loginModal.isVisible}
        >
          <LoginModal closedHandler={loginModalClosed} />
        </ModalCenterLayout>
      )}
    </MypageWrap>
  );
};

export default MypageHome;
