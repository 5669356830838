import { RotateIcon } from "cssReset/icon";
import { SimpleMeasureResult } from "interface/interface.mypage";
import { useNavigate } from "react-router-dom";
import {
  MeasureResultBox,
  MeasureResultCard,
  NoDataBox,
  NoDataCard,
  SubItemWrapper,
  TestMeasureListItemBox,
} from "style/mypage";
import { formatDate } from "utils/util";

interface ListDataProps {
  data: SimpleMeasureResult[];
}

const MeasureListItem = ({ data }: ListDataProps) => {
  const navigate = useNavigate();
  console.log(data);
  return (
    <TestMeasureListItemBox>
      {data && data.length !== 0 ? (
        <div>
          {data.map((item, index) => (
            <MeasureResultBox key={item.simpleMeasureResultId}>
              <div className="title">
                <p>층간소음 간이 측정</p>
                <span>{formatDate(item.startDt)} 측정</span>
              </div>
              <MeasureResultCard>
                <ul>
                  <li>
                    <div className="name">
                      <strong>최고소음도</strong>{" "}
                      <span>측정시간 내 발생한 최고 소음도</span>
                    </div>
                    <div className="value">
                      {item.dayMaxNoise > 0 && (
                        <div className={item.dayMaxNoise > 57 ? "warning" : ""}>
                          {item.dayMaxNoise.toFixed(0)}
                        </div>
                      )}
                      {item.nightMaxNoise > 0 && (
                        <div
                          className={item.nightMaxNoise > 52 ? "warning" : ""}
                        >
                          {item.nightMaxNoise.toFixed(0)}
                        </div>
                      )}
                    </div>
                  </li>
                  <li>
                    <div className="name">
                      <strong>1분 등가소음도(직접충격소음)</strong>{" "}
                      <span>1분간 측정한 소음도의 평균값</span>
                    </div>
                    <div className="value">
                      {item.dayOneMinuteLeq > 0 && (
                        <div
                          className={item.dayOneMinuteLeq > 57 ? "warning" : ""}
                        >
                          {item.dayOneMinuteLeq.toFixed(0)}
                        </div>
                      )}
                      {item.nightOneMinuteLeq > 0 && (
                        <div
                          className={
                            item.nightOneMinuteLeq > 52 ? "warning" : ""
                          }
                        >
                          {item.nightOneMinuteLeq.toFixed(0)}
                        </div>
                      )}
                    </div>
                  </li>
                  <li>
                    <div className="name">
                      <strong>5분 등가소음도</strong>{" "}
                      <span>5분간 측정한 소음도의 평균값</span>
                    </div>
                    <div className="value">
                      {item.dayFiveMinuteLeq > 0 && (
                        <div
                          className={
                            item.dayFiveMinuteLeq > 57 ? "warning" : ""
                          }
                        >
                          {item.dayFiveMinuteLeq.toFixed(0)}
                        </div>
                      )}
                      {item.nightFiveMinuteLeq > 0 && (
                        <div
                          className={
                            item.nightFiveMinuteLeq > 52 ? "warning" : ""
                          }
                        >
                          {item.nightFiveMinuteLeq.toFixed(0)}
                        </div>
                      )}
                    </div>
                  </li>
                  <li className="last-item">
                    <div className="name">
                      <strong>
                        기준치 초과 소음
                        <br /> 발생횟수
                      </strong>{" "}
                    </div>
                    <span className="hr"></span>
                    <div className="last-value">
                      <span className="sub-name">최고소음도</span>{" "}
                      <strong>
                        {item.dayExceedCount + item.nightExceedCount}
                      </strong>
                      <span> 회</span>
                    </div>
                  </li>
                </ul>
              </MeasureResultCard>
              <SubItemWrapper>
                <div className="detail-veiw-btn">측정결과 자세히 보기</div>
                <span></span>
                <div className="rotate">
                  <span className="icon-box">
                    <RotateIcon />
                  </span>
                  측정 다시 하기
                </div>
              </SubItemWrapper>
            </MeasureResultBox>
          ))}
        </div>
      ) : (
        <NoDataBox>
          <div className="title">
            <p>층간소음 간이 측정</p>
            <span>검사 정보 없음</span>
          </div>
          <NoDataCard>
            <p>
              측정 결과가 없습니다. <br />
              스마트폰으로 간편하게, 지금 측정해보세요.
            </p>
            <button
              onClick={() => {
                navigate("/measure");
              }}
            >
              층간소음 측정하러 가기
            </button>
          </NoDataCard>
        </NoDataBox>
      )}
    </TestMeasureListItemBox>
  );
};

export default MeasureListItem;
