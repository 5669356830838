import { inquiryHeaderTypeState } from "atoms/inquiry.state";
import { InquiryDTO } from "interface/interface.inquiry";
import { useEffect, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  AnswerBox,
  ContentsWrapper,
  InquiryListItems,
  InquiryListWrap,
  QuestionBox,
} from "style/inquiry";
import { formatDateTime } from "utils/util";
import InquiryAnswerBox from "./inquiry.answer.box";
import { DownArrowIconX24 } from "cssReset/icon";
import { alramClickedState } from "atoms/alram.state";
import { useSearchParams } from "react-router-dom";

interface InquiryDataProps {
  data: InquiryDTO[];
  handleNext: () => void;
}

const InquiryList = ({ data, handleNext }: InquiryDataProps) => {
  const setInquiryHeaderType = useSetRecoilState(inquiryHeaderTypeState);
  const alramLinkState = useRecoilValue(alramClickedState);

  const [selectedInquiryId, setSelectedInquiryId] = useState<number | null>(
    null
  ); // 상태 추가

  const [contentHeight, setContentHeight] = useState(0);
  const [clicked, setClicked] = useState(false); // 클릭 여부 추적 상태 추가

  const contentRef = useRef<HTMLDivElement>(null); // ✅ ContentsBox 참조
  const titleRefs = useRef<Map<number, HTMLDivElement | null>>(new Map()); // ✅ 각 문의 항목별 ref 저장

  const [searchParams] = useSearchParams();
  const moveTargetId = searchParams.get("targetId") || "";

  useEffect(() => {
    setInquiryHeaderType({ centerMode: false, btnMode: false });
  }, [setInquiryHeaderType]);

  const handleQuestionBoxClick = (inquiryId: number) => {
    // 클릭된 Inquiry의 ID를 상태로 설정
    setSelectedInquiryId(inquiryId === selectedInquiryId ? null : inquiryId); // 같은 항목 클릭 시 선택 해제
  };

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight); // 실제 높이 저장
    }
  }, [selectedInquiryId]); // selectedInquiryId가 변경될 때마다 height 업데이트

  useEffect(() => {
    if (!clicked && moveTargetId !== null) {
      setTimeout(() => {
        const targetRef = titleRefs.current.get(
          alramLinkState.commentId as number
        );
        if (targetRef) {
          targetRef.click(); // 해당 요소 클릭
          setClicked(true);
        }
      }, 350);
    }
  }, [moveTargetId, clicked]);

  useEffect(() => {
    if (
      alramLinkState.linkYn &&
      !clicked &&
      alramLinkState.commentId !== null
    ) {
      setTimeout(() => {
        const targetRef = titleRefs.current.get(
          alramLinkState.commentId as number
        );
        if (targetRef) {
          targetRef.click(); // 해당 요소 클릭
          setClicked(true);
        }
      }, 350);
    }
  }, [alramLinkState.linkYn, clicked]);

  return (
    <InquiryListWrap>
      <div className="top-box">
        1 : 1 문의 <span>{data.length} 건</span>
      </div>
      <div className="btn-box">
        <button onClick={handleNext}>문의하기</button>
      </div>

      <ContentsWrapper>
        {data.length > 0 ? (
          data.map((list) => (
            <InquiryListItems key={list.inquiryId}>
              <QuestionBox
                onClick={() => handleQuestionBoxClick(list.inquiryId)} // 클릭 시 이벤트 처리
                className={
                  selectedInquiryId === list.inquiryId ? "isActive" : ""
                } // 클릭된 항목에 스타일 적용
                ref={(el) => titleRefs.current.set(list.inquiryId, el)} // ✅ 각 문의 항목의 ref 저장
              >
                <div className="l-box">
                  <p className="title">{list.title}</p>
                  <div className="answer-state-box">
                    {list.answer ? (
                      <span className="answer-complete">답변완료</span>
                    ) : (
                      <span className="answer-pandding">답변대기</span>
                    )}
                    <span className="date">{formatDateTime(list.regDt)}</span>
                  </div>
                </div>
                <div className="r-box">
                  <span className="icon-box">
                    <DownArrowIconX24 />
                  </span>
                </div>
              </QuestionBox>

              {selectedInquiryId === list.inquiryId && (
                <AnswerBox
                  ref={contentRef}
                  initial={{ maxHeight: 0, opacity: 0 }}
                  animate={{
                    maxHeight:
                      selectedInquiryId === list.inquiryId
                        ? contentHeight + 2
                        : 0, // 동적으로 높이 적용
                    opacity: selectedInquiryId === list.inquiryId ? 1 : 0,
                  }}
                  transition={{ duration: 0.3, ease: "easeInOut" }}
                  style={{
                    overflow: "hidden",
                    display:
                      selectedInquiryId === list.inquiryId ? "block" : "none",
                  }}
                >
                  <InquiryAnswerBox data={list} />
                </AnswerBox>
              )}
            </InquiryListItems>
          ))
        ) : (
          <div className="no-list-txt">문의 내역이 없습니다.</div>
        )}
      </ContentsWrapper>
    </InquiryListWrap>
  );
};

export default InquiryList;
