import {
  RepleIconX14,
  RightArrowIconX16,
  WhiteLineHeartIconX14,
} from "cssReset/icon";
import { ReviewItem } from "interface/interface.review";
import { useNavigate } from "react-router-dom";
import {
  BestReviewContentsBox,
  BestReviewListBox,
  TopBox,
} from "style/main.home";

interface BestReviewDTOProps {
  listData: ReviewItem[];
}

const BestReviewList = ({ listData }: BestReviewDTOProps) => {
  const navigate = useNavigate();

  const handleTotalViewClicked = () => {
    navigate("/review");
  };

  const handleReviewItemClicked = (reviewId: number) => {
    navigate(`/review/detail/${reviewId}`);
  };

  return (
    <BestReviewListBox>
      <TopBox>
        <p>베스트 후기</p>
        <span className="total-review-btn" onClick={handleTotalViewClicked}>
          전체보기{" "}
          <span className="icon-box">
            <RightArrowIconX16 />{" "}
          </span>
        </span>
      </TopBox>
      <BestReviewContentsBox>
        <div className="inner">
          {listData &&
            listData.slice(0, 5).map((list) => (
              <div
                className="list-item"
                key={list.reviewId}
                onClick={() => {
                  handleReviewItemClicked(list.reviewId);
                }}
              >
                <div className="img-box">
                  {list.files.length > 0 ? (
                    <img src={list.files[0].accessUrl} alt="" />
                  ) : (
                    ""
                  )}
                  <div className="img-bottom-con-box">
                    <div className="like-box">
                      <span className="icon-box">
                        <WhiteLineHeartIconX14 />
                      </span>
                      <span>{list.reviewLikeCount}</span>
                    </div>
                    <div className="comment-box">
                      <span className="icon-box">
                        <RepleIconX14 />
                      </span>
                      <span>{list.reviewCommentCount}</span>
                    </div>
                  </div>
                </div>
                <div className="sub-box">
                  <p className="review-title">{list.title}</p>
                  <div className="info-box">
                    <div className="profile-img-box">
                      {list.userProfileUrl ? (
                        <img src={list.userProfileUrl} alt="" />
                      ) : (
                        <img src="/assets/icon/userprofile.png" alt="" />
                      )}
                    </div>
                    <span>{list.userName}</span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </BestReviewContentsBox>
    </BestReviewListBox>
  );
};

export default BestReviewList;
