import { NoDataBox, NoDataCard, TestMeasureListItemBox } from "style/mypage";

interface ListDataProps {
  data: any[];
}

const SimpleTestListItem = ({ data }: ListDataProps) => {
  return (
    <TestMeasureListItemBox>
      {data && data.length !== 0 ? (
        <div>
          {data.map((item, index) => (
            <div>test</div>
          ))}
        </div>
      ) : (
        <NoDataBox>
          <div className="title">
            <p>층간소음 유형 간이검사</p>
            <span>검사 정보 없음</span>
          </div>
          <NoDataCard>
            <p>검사 결과가 없습니다.</p>
            <button>5분만에 나의 층간소음 유형 알아보기</button>
          </NoDataCard>
        </NoDataBox>
      )}
    </TestMeasureListItemBox>
  );
};

export default SimpleTestListItem;
