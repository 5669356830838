import { MeasureParams } from "interface/interface.measure";
import axiosInstance from "../plugin/axios";
import { LoadListParams } from "interface/interface.mypage";
import { AlramDto } from "interface/interface.alram";

// 내가 작성한 후기
export const fetchLoadMyReview = async (params: any) => {
  try {
    const response = await axiosInstance.get(`/review/my-review`, {
      params: params,
    });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

// 내가 단 댓글
export const fetchLoadMyReviewComment = async (params: any) => {
  try {
    const response = await axiosInstance.get(`/review/commented-review`, {
      params: params,
    });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

// 내가 좋아요한 후기
export const fetchLoadLikeMyReview = async (params: any) => {
  try {
    const response = await axiosInstance.get(`/review/liked-review`, {
      params: params,
    });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

// 알림 리스트
export const fetchAlramHandler = async (params: AlramDto) => {
  try {
    const response = await axiosInstance.get(`/notification`, { params });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

// 간이 검사 결과 리스트
export const fetchLoadSimpleTestResultList = async (params: LoadListParams) => {
  try {
    const response = await axiosInstance.get(`/test-result/simple`, { params });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

// 심화 검사 결과 리스트
export const fetchLoadDeepTestResultList = async (params: LoadListParams) => {
  try {
    const response = await axiosInstance.get(`/test-result/deep`, { params });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

// 측정 결과 리스트
export const fetchLoadMeasureResultList = async (params: LoadListParams) => {
  try {
    const response = await axiosInstance.get(`/measure-result`, { params });
    return response.data;
  } catch (error) {
    throw new Error();
  }
};
