import { previewHeaderLoginModalState } from "atoms/layout.state";
import {
  blockUserState,
  reviewBlockModalState,
  reviewEditModalState,
  reviewInformationState,
  snsModalState,
} from "atoms/modal.state";
import LoginModal from "components/auth/login/modal/login.modal";
import ModalCenterLayout from "components/modal/modal.center.layout";
import {
  BackArrowIcon,
  ReveiwDetailHeaderLeftIcon,
  ReviewDetailHeaderRightIcon,
} from "cssReset/icon";
import { HeaderTitleProps } from "interface/interface.header";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { closeModal } from "utils/modal.util";

const BackArrowReviewHeader = ({
  headerTitle,
  detailBtnBox = false,
  columnMode = false,
}: HeaderTitleProps) => {
  const navigate = useNavigate();
  const handleOnclick = () => {
    navigate(-1);
  };
  const [loginModal, setLoginModal] = useRecoilState(
    previewHeaderLoginModalState
  );
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    setSnsModal({ isOpen: false, isVisible: false });
    setReviewEditModal({ isOpen: false, isVisible: false });
    setBlockModalState({ isOpen: false, isVisible: false });
    setBlockUserData(null);
  }, []); // ✅ 빈 배열([])을 넣어 **한 번만 실행**

  const setSnsModal = useSetRecoilState(snsModalState);
  const setReviewEditModal = useSetRecoilState(reviewEditModalState);
  const reviewInfoData = useRecoilValue(reviewInformationState);
  const userId = localStorage.getItem("userId");
  const setBlockModalState = useSetRecoilState(reviewBlockModalState);
  const setBlockUserData = useSetRecoilState(blockUserState);

  const openSnsModal = () => {
    setSnsModal({ isOpen: true, isVisible: true });
  };

  const openReviewEditModal = () => {
    setReviewEditModal({ isOpen: true, isVisible: true });
  };

  const openBlockReviewModal = (
    blockUserId: string | null,
    blockUserName: string | null,
    targetId?: number | null,
    type?: string,
    reviewId?: number | null
  ) => {
    setBlockUserData({ blockUserId, blockUserName, targetId, type, reviewId });
    setBlockModalState({ isOpen: true, isVisible: true });
  };

  const loginModalClosed = () => {
    closeModal(setLoginModal);
    navigate("/review");
  };

  return (
    <HeaderContainer>
      <div className="icon-box" onClick={handleOnclick}>
        <BackArrowIcon />
      </div>
      <div className="header-title">
        <p>{headerTitle}</p>
      </div>
      {detailBtnBox && (
        <div className="review-detail-btn-box">
          <div className="icon-box" onClick={openSnsModal}>
            <ReveiwDetailHeaderLeftIcon />
          </div>
          {reviewInfoData.reviewRegUserId === userId ? (
            <div className="icon-box" onClick={openReviewEditModal}>
              <ReviewDetailHeaderRightIcon />
            </div>
          ) : accessToken ? (
            <div
              className="icon-box"
              onClick={() => {
                openBlockReviewModal(
                  reviewInfoData.reviewRegUserId,
                  reviewInfoData.blockUserName,
                  reviewInfoData.reviewId,
                  "review",
                  reviewInfoData.reviewId
                );
              }}
            >
              <ReviewDetailHeaderRightIcon />
            </div>
          ) : (
            <div
              className="icon-box"
              onClick={() => {
                setLoginModal({ isOpen: true, isVisible: true });
              }}
            >
              <ReviewDetailHeaderRightIcon />
            </div>
          )}
        </div>
      )}
      {columnMode && (
        <div className="review-detail-btn-box">
          <div className="icon-box" onClick={openSnsModal}>
            <ReveiwDetailHeaderLeftIcon />
          </div>
        </div>
      )}
      {loginModal && (
        <ModalCenterLayout
          closedHandler={loginModalClosed}
          isVisible={loginModal.isVisible}
        >
          <LoginModal closedHandler={loginModalClosed} />
        </ModalCenterLayout>
      )}
    </HeaderContainer>
  );
};

export default BackArrowReviewHeader;

const HeaderContainer = styled.header`
  height: 5.6rem;
  display: grid;
  grid-template-columns: 4.8rem auto;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  z-index: 99;
  .icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
  .header-title {
    display: flex;
    align-items: center;
    p {
      font-size: 1.6rem;
      font-weight: 500;
      letter-spacing: -0.06rem;
      line-height: 2rem;
      transform: translateY(1px);
    }
  }
  .review-detail-btn-box {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    .icon-box {
      display: flex;
      width: 4.8rem;
      align-items: center;
      justify-content: center;
      svg {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }
`;
