import { SimpleMeasureResult } from "interface/interface.measure";
import { useNavigate } from "react-router-dom";
import { MeasureHomeRecentlyRecordWrapper, NoData } from "style/measure";
import {
  formatDateTimeAddSecYear,
  formatDateTimeMTime,
  getTimeDifference,
} from "utils/util";

interface DataProps {
  data: SimpleMeasureResult[];
}

const MeasureHomeRecentlyRecord = ({ data }: DataProps) => {
  const navigate = useNavigate();
  const sortedData = [...data]
    .sort((a, b) => new Date(b.regDt).getTime() - new Date(a.regDt).getTime())
    .slice(0, 5); // 최근 5개만 가져오기
  return (
    <MeasureHomeRecentlyRecordWrapper>
      {sortedData && sortedData.length > 0 ? (
        sortedData.map((item, index) => (
          <div
            key={index}
            className="itme-wrapper"
            onClick={() => {
              navigate(
                `/measure/simple/recording/result/detail/${item.simpleMeasureResultId}`
              );
            }}
          >
            <div className="title">
              <p>{formatDateTimeMTime(item.regDt)}</p>
              <span>간이 측정</span>
            </div>
            <ul>
              <li>
                <div className="name">측정 장소</div>
                <div className="value">{item.location}</div>
              </li>
              <li>
                <div className="name">측정 기간</div>
                <div className="value">
                  <div>
                    {formatDateTimeAddSecYear(item.startDt)} ~{" "}
                    {formatDateTimeAddSecYear(item.endDt)}
                  </div>
                  <div>({getTimeDifference(item.startDt, item.endDt)})</div>
                </div>
              </li>
              <li>
                <div className="name">측정 요약</div>
                <div className="value summary">
                  {item.dayExceedCount != null && item.dayExceedCount !== 0 && (
                    <div>{`기준치 이상 소음 발생(순간소음) ${item.dayExceedCount}(주간)회`}</div>
                  )}
                  {item.nightExceedCount != null &&
                    item.nightExceedCount !== 0 && (
                      <div>{`기준치 이상 소음 발생(순간소음) ${item.nightExceedCount}(야간)회`}</div>
                    )}
                  <div className="title-gap">
                    <span>최고소음도</span>
                    {item.dayMaxNoise ? (
                      <>
                        {" "}
                        <strong>{item.dayMaxNoise.toFixed(2)}</strong>dB(주간)
                      </>
                    ) : (
                      ""
                    )}
                    {item.dayMaxNoise && item.nightMaxNoise ? " / " : ""}
                    {item.nightMaxNoise ? (
                      <>
                        <strong>{item.nightMaxNoise.toFixed(2)}</strong>dB(야간)
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="title-gap">
                    <span>1분 등가소음도</span>
                    {item.dayOneMinuteLeq ? (
                      <>
                        <strong>{item.dayOneMinuteLeq.toFixed(2)}</strong>
                        dB(주간)
                      </>
                    ) : (
                      ""
                    )}
                    {item.dayOneMinuteLeq && item.nightOneMinuteLeq
                      ? " / "
                      : ""}
                    {item.nightOneMinuteLeq ? (
                      <>
                        <strong>${item.nightOneMinuteLeq.toFixed(2)}</strong>
                        dB(야간)
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* 5분 등가소음도는 둘다 없으면 화면에 표시 안함. */}
                  {(item.dayFiveMinuteLeq || item.nightFiveMinuteLeq) && (
                    <div className="title-gap">
                      <span>5분 등가소음도</span>
                      {item.dayFiveMinuteLeq ? (
                        <>
                          <strong>{item.dayFiveMinuteLeq.toFixed(2)}</strong>
                          dB(주간)
                        </>
                      ) : (
                        ""
                      )}
                      {item.dayFiveMinuteLeq && item.nightFiveMinuteLeq
                        ? " / "
                        : ""}
                      {item.nightFiveMinuteLeq ? (
                        <>
                          <strong>{item.nightFiveMinuteLeq.toFixed(2)}</strong>
                          dB(야간)
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
              </li>
            </ul>
          </div>
        ))
      ) : (
        <NoData>
          <p>측정 결과가 없습니다.</p>
        </NoData>
      )}
    </MeasureHomeRecentlyRecordWrapper>
  );
};

export default MeasureHomeRecentlyRecord;
