import styled from "styled-components";
import { motion } from "framer-motion";

export const InquiryContainer = styled.div``;

export const Step = styled(motion.div)``;

export const InquiryListWrap = styled.div`
  padding: 2.4rem 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  .top-box {
    padding: 0.8rem 0;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.8rem;
    display: flex;
    gap: 0.8rem;
    border-bottom: 1px solid #cecece;
    span {
      color: #225298;
    }
  }
  .btn-box {
    display: flex;
    button {
      width: 100%;
      outline: none;
      padding: 1.5rem;
      border: 1px solid #225298;
      background-color: white;
      border-radius: 0.5rem;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 2.4rem;
      color: #225298;
    }
  }
`;

export const InquiryListItems = styled.div``;

export const QuestionBox = styled.div`
  display: flex;
  align-items: center;
  padding: 1.6rem 0.8rem 1.5rem;
  border-bottom: 1px solid #cecece;
  .l-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    .title {
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 1.6rem;
      letter-spacing: -0.06rem;
    }
    .date {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.2rem;
      color: #575757;
    }
  }
  .r-box {
    flex: 0;
    .icon-box {
      display: flex;
      width: 2.4rem;
      height: 2.4rem;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  &.isActive {
    background-color: #ececec;
    .r-box {
      .icon-box {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
`;

export const ContentsWrapper = styled.div`
  .no-list-txt {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
    text-align: center;
    padding: 4rem 0;
  }
`;

export const AnswerBox = styled(motion.div)`
  padding: 0 0.8rem;
  .no-answer-txt {
    padding: 0.8rem;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
  }
`;

export const AnswerInner = styled.div`
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  border-bottom: 1px solid #a1a1a1;
  .answer-txt {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
    white-space: pre-wrap;
  }
  .answer-dt {
    padding: 0.8rem 0 1.6rem;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
  }
`;

export const QuestionInner = styled.div`
  padding: 0.8rem 0.8rem 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  border-bottom: 1px solid #cecece;
  p {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
    white-space: pre-wrap;
  }
`;

export const SliderWrapper = styled.div`
  margin-top: 2.4rem;
  position: relative;
  .inner {
    padding: 0 1.4rem;
    .img-box {
      height: 20rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 0.5rem;
      }
    }
    .slick-arrow-custom {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      &.slick-arrow-left {
        left: -1.4rem;
      }
      &.slick-arrow-right {
        right: -1.4rem;
      }
    }
  }
  .slide-counter {
    position: absolute;
    bottom: 0.4rem;
    right: 2rem;
    padding: 0.2rem 0.4rem;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 10rem;
    font-weight: 400;
    line-height: 1.2rem;
    font-size: 1rem;
  }
`;

export const InquiryTypeContainer = styled.div`
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  .title {
    padding: 2.4rem 0;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 2.4rem;
  }
  .btn-box {
    button {
      border: none;
      outline: none;
      border-radius: 0.5rem;
      width: 100%;
      background-color: #225298;
      color: white;
      padding: 1rem;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 2rem;
      letter-spacing: -0.06rem;
      &:disabled {
        background-color: #cecece;
      }
    }
  }
`;

export const InquiryTypeBox = styled.div`
  .input-box {
    display: flex;
    padding: 1.6rem 0.8rem;
    align-items: center;
    label {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 2rem;
      .icon-box {
        width: 1.6rem;
        height: 1.6rem;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
    input[type="radio"] {
      display: none;
    }
  }
`;

export const InquiryRegistWrap = styled.div`
  padding: 2.4rem 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  .title-box {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    .input-box {
      position: relative;
      input[type="text"] {
        width: 100%;
        border: 0;
        border-bottom: 1px solid #cecece;
        padding: 0.8rem 0.8rem 0.7rem;
        line-height: 2.4rem;
        font-size: 1.2rem;
        border-radius: 0;
        &:focus {
          outline: none;
        }
      }
      span {
        display: flex;
        position: absolute;
        bottom: 0;
        left: 50%;
        background-color: #1f75fe;
        width: 0;
        height: 2px;
        border-radius: 2px;
        transform: translateX(-50%);
        transition: all 0.5s;
      }
      input:focus ~ span {
        width: 100%;
      }
    }
  }
  .contents-box {
    textarea {
      border: 1px solid #cecece;
      border-radius: 0.5rem;
      outline: 0;
      resize: none;
      width: 100%;
      min-height: 16rem;
      padding: 0.8rem;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
    }
  }
`;

export const TextLengthBox = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.6rem;
  color: #a1a1a1;
  text-align: right;
  padding: 0 0.4rem;
`;

export const InfoTxt = styled.div`
  padding: 1.6rem 0;
  p {
    white-space: pre-wrap;
    color: #a1a1a1;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
  }
`;
