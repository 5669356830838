import styled from "styled-components";

export const Container = styled.div`
  height: calc(100vh - 5.6rem);
  padding: 1.6rem;
  .hr {
    margin: 2.4rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 4.8rem;
    span {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
      display: flex;
      padding: 0 1.6rem;
      background-color: white;
      z-index: 1;
    }
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 21.3rem;
      height: 1px;
      background-color: #242629;
      transform: translate(-50%, -50%);
    }
  }
`;

export const EmailInputWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
  input[type="text"] {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #cecece;
    padding: 0.8rem 0.8rem 0.7rem;
    line-height: 2.4rem;
    font-size: 1.2rem;
    border-radius: 0;
    &:focus {
      outline: none;
    }
  }
`;

export const InputBox = styled.div`
  position: relative;
  span {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 50%;
    background-color: #1f75fe;
    width: 0;
    height: 2px;
    border-radius: 2px;
    transform: translateX(-50%);
    transition: all 0.5s;
  }
  input:focus ~ span {
    width: 100%;
  }
`;

export const SubmitButton = styled.button`
  width: 100%;
  outline: none;
  border: none;
  padding: 1rem;
  color: white;

  border-radius: 0.5rem;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  transition: background-color 0.3s;
`;

export const ErrorMessage = styled.span`
  color: #ed2115;
  font-size: 1rem;
  margin-top: 0.4rem;
  position: absolute;
  left: 0.4rem;
  top: 4rem;
`;

export const SocialLoginWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

export const LinkBox = styled.div`
  margin-top: 2.4rem;
  display: flex;
  justify-content: center;
  a {
    padding: 0.4rem;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
    border-bottom: 1px solid #242629;
  }
`;

export const AppleLoginBtnContainer = styled.div`
  button {
    display: flex;
    width: 100%;
    align-items: center;
    border: 1px solid #bfbfbf;
    outline: none;
    background-color: white;
    border-radius: 0.5rem;
    padding: 0 0.8rem;
    span,
    p {
      display: flex;
    }
    p {
      flex: 1;
      justify-content: center;
      align-items: center;
      font-family: "SFPro";
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 2rem;
      color: rgba(0, 0, 0, 0.85);
      svg {
        height: 100%;
      }
    }
  }
`;

export const NotLoginBox = styled.div`
  padding: 3.2rem 0;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  p {
    font-size: 1.3rem;
    text-align: center;
  }
  button {
    font-size: 1.3rem;
    text-decoration: underline;
    font-weight: 500;
    border: none;
    outline: none;
    background-color: transparent;
  }
`;
