import { LeftArrowIcon, RightArrowIcon } from "cssReset/icon";
import { InquiryDTO } from "interface/interface.inquiry";
import { useState } from "react";
import Slider from "react-slick";
import {
  AnswerBox,
  AnswerInner,
  QuestionInner,
  SliderWrapper,
} from "style/inquiry";
import { formatDateTime } from "utils/util";

interface InquiryAnswerListDto {
  data: InquiryDTO;
}

const InquiryAnswerBox = ({ data }: InquiryAnswerListDto) => {
  const [currentSlide, setCurrentSlide] = useState(1); // 현재

  console.log(data);

  //  슬라이드 번호
  const sliderSetting = {
    infinite: data.files && data.files.length > 1,
    speed: 500,
    slidesToShow: 1,
    rows: 1,
    slidesToScroll: 1,
    prevArrow: <LeftArrowIcon color="#000" />, // 이전 버튼에 아이콘 추가
    nextArrow: <RightArrowIcon color="#000" />, // 다음 버튼에 아이콘 추가
    afterChange: (current: number) => setCurrentSlide(current + 1), // 현재 슬라이드 번호 업데이트
  };
  return (
    <>
      <QuestionInner>
        <p>{data.contents}</p>
        {data.files && data.files.length > 0 && (
          <SliderWrapper>
            <div className="inner">
              <Slider {...sliderSetting}>
                {data.files.map((img) => (
                  <div key={img.fileId} className="img-box">
                    <img src={img.accessUrl} alt={img.realFileName} />
                  </div>
                ))}
              </Slider>
            </div>
            {/* 현재 슬라이드 위치 표시 */}
            <div className="slide-counter">
              {currentSlide} / {data.files.length}
            </div>
          </SliderWrapper>
        )}
      </QuestionInner>
      {data.answer && data.answer !== null ? (
        <AnswerInner>
          <p className="answer-txt">{data.answer}</p>

          {data.files && data.files.length > 0 && (
            <SliderWrapper>
              <div className="inner">
                <Slider {...sliderSetting}>
                  {data.files.map((img) => (
                    <div key={img.fileId} className="img-box">
                      <img src={img.accessUrl} alt={img.realFileName} />
                    </div>
                  ))}
                </Slider>
              </div>
              {/* 현재 슬라이드 위치 표시 */}
              <div className="slide-counter">
                {currentSlide} / {data.files.length}
              </div>
            </SliderWrapper>
          )}
          <div className="answer-dt">
            답변일 {formatDateTime(String(data.answerDt))}
          </div>
        </AnswerInner>
      ) : (
        <AnswerBox>
          <p className="no-answer-txt">답변 대기 상태입니다.</p>
        </AnswerBox>
      )}
    </>
  );
};

export default InquiryAnswerBox;
