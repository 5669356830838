import { NoiseData } from "interface/interface.measure";

// 주간, 야간 구분 함수
export const classifyDayNightData = (
  data: { time: string; value: number }[]
) => {
  const dayData: NoiseData[] = [];
  const nightData: NoiseData[] = [];

  data.forEach((item) => {
    const hour = new Date(item.time).getHours();

    if (hour >= 6 && hour < 22) {
      dayData.push(item);
    } else {
      nightData.push(item);
    }
  });

  return { dayData, nightData };
};

// 토탈 데이터에서 주, 야간으로 구분 후 57, 52넘는 값만 추출
export const classifyAndCountExceedingValues = (
  data: { time: string; value: number }[]
) => {
  let dayCount = 0;
  let nightCount = 0;

  data.forEach((item) => {
    const hour = new Date(item.time).getHours();

    if (hour >= 6 && hour < 22) {
      // 주간: value가 57 이상이면 카운트 증가
      if (item.value >= 57) {
        dayCount++;
      }
    } else {
      // 야간: value가 52 이상이면 카운트 증가
      if (item.value >= 52) {
        nightCount++;
      }
    }
  });

  return {
    dayExceedCount: dayCount > 0 ? dayCount : 0,
    nightExceedCount: nightCount > 0 ? nightCount : 0,
  };
};

export const classifyAndFilterExceedingValues = (
  data: { time: string; value: number }[]
) => {
  const dayExceeded: { time: string; value: number }[] = [];
  const nightExceeded: { time: string; value: number }[] = [];

  data.forEach((item) => {
    const hour = new Date(item.time).getHours();

    if (hour >= 6 && hour < 22) {
      if (item.value >= 57) {
        dayExceeded.push(item);
      }
    } else {
      if (item.value >= 52) {
        nightExceeded.push(item);
      }
    }
  });

  return {
    dayExceeded,
    nightExceeded,
  };
};

// 상위 3가지 value 구하기
export const getTopThreeValues = (data: (NoiseData | null)[]) => {
  return data
    .filter((item): item is NoiseData => item !== null) // 🔥 null 값 필터링
    .sort((a, b) => b.value - a.value) // value 기준 내림차순 정렬
    .slice(0, 3) // 상위 3개 데이터 가져오기
    .map((item) => ({
      ...item,
      value: parseFloat(item.value.toFixed(2)), // ✅ 소수점 2자리까지 변환
    }));
};

// 1분 등가소음도(Leq) 계산 함수 > 1분간 측정한 값을 평균
export const calculateLeq = (
  noiseData: NoiseData[]
): { time: string; value: number }[] => {
  const groupedResult: { time: string; value: number }[] = [];

  const groupSize = 60; // 1분 = 60개 샘플

  for (let i = 0; i < noiseData.length; i += groupSize) {
    const group = noiseData.slice(i, i + groupSize);
    if (group.length === 0) continue;

    const avg = group.reduce((sum, item) => sum + item.value, 0) / group.length;

    groupedResult.push({
      time: group[0].time, // 그룹의 시작 시간 표시
      value: parseFloat(avg.toFixed(2)),
    });
  }

  return groupedResult;
};

// 최고 1분 등가소음도(Leq) 추출 단일 객체로 반환
// export const getMaxLeq = (
//   leqData: { time: string; value: number }[]
// ): { time: string; value: number } | null => {
//   if (leqData.length === 0) return null;
//   return leqData.reduce((max, data) => (data.value > max.value ? data : max));
// };

// 최고 1분 등가소음도(Leq) 추출 (배열로 반환)
export const getMaxLeq = (
  leqData: { time: string; value: number }[]
): { time: string; value: number }[] => {
  if (leqData.length === 0) return [];
  const max = leqData.reduce((max, data) =>
    data.value > max.value ? data : max
  );
  return [max]; // 🔥 단일 객체를 배열로 감싸서 반환
};

// 5분 등가소음도(Leq) 계산 함수 > 5분간 측정한 값을 평균
export const calculateFiveMinuteLeq = (
  leqData: { time: string; value: number }[]
): { time: string; value: number }[] => {
  const groupedResult: { time: string; value: number }[] = [];
  const groupSize = 300; // 5분 = 300초

  // 데이터가 300개 미만이면 0 반환
  if (leqData.length < groupSize) {
    return [
      {
        time: leqData[0]?.time || "N/A",
        value: 0,
      },
    ];
  }

  for (let i = 0; i < leqData.length; i += groupSize) {
    const group = leqData.slice(i, i + groupSize);
    if (group.length === 0) continue;

    const avg = group.reduce((sum, item) => sum + item.value, 0) / group.length;

    groupedResult.push({
      time: group[0].time,
      value: parseFloat(avg.toFixed(2)),
    });
  }

  return groupedResult;
};

// 최고 5분 등가소음도(Leq) 추출
export const getMaxFiveMinuteLeq = (
  fiveMinuteLeqData: { time: string; value: number }[]
): { time: string; value: number } | null => {
  if (fiveMinuteLeqData.length === 0) return null;
  return fiveMinuteLeqData.reduce((max, data) =>
    data.value > max.value ? data : max
  );
};

// 개별적으로 주간 또는 야간 데이터에서 배경 소음도 찾기
export const getMinBackgroundNoise = (data: NoiseData[]) => {
  return data.length > 0
    ? data.reduce((min, d) => (d.value < min.value ? d : min))
    : null;
};

// 차트 색상 지정 로직 (시간까지 고려)
export const colorSelector = (value: number, label: string) => {
  const hour = new Date(label).getHours();

  // 주간: 06 ~ 21시 (22시 미만), 야간: 22 ~ 05시
  const isDaytime = hour >= 6 && hour < 22;
  const threshold = isDaytime ? 57 : 52;

  if (value >= 70) {
    return "#FF2C20"; // 빨강 (70 이상)
  } else if (value >= threshold) {
    return "#FFA29D"; // 연한 빨강 (기준 이상)
  } else {
    return "#9CD0DE"; // 파랑 (기준 미만)
  }
};
