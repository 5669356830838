import { fetchLoadRecordResultDetail } from "api/measure";
import { SimpleMeasureResult } from "interface/interface.measure";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MeasureResultContainer, ResultWrapper } from "style/measure";
import { parseResultData } from "utils/data.parsing";
import MeasureRecordingResultHeader from "./measure.recording.result.header";
import MeasureRecordResultTopInfo from "components/measure/components/measure.record.result.top.info";
import MeasureRecordResultTotalCount from "components/measure/components/measure.record.result.total.count";
import MeasureRecordResultSummary from "components/measure/components/measure.record.result.summay";
import MeasureRecordResultChart from "components/measure/components/measure.record.result.chart";

const MeasureRecordResultDetail = () => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<SimpleMeasureResult | null>(null);
  const parsedData = data && parseResultData(data?.result);
  const isLoading = !data; // 데이터가 없으면 로딩 중

  console.log(data);

  useEffect(() => {
    const loadRecordResultDetail = async () => {
      try {
        const response = await fetchLoadRecordResultDetail(Number(id));
        if (response.result) {
          setData(response.result[0]);
        }
      } catch (error) {
        console.error(error);
      }
    };
    loadRecordResultDetail();
  }, []);

  return (
    <MeasureResultContainer>
      <MeasureRecordingResultHeader />
      <ResultWrapper>
        {isLoading ? (
          <p>로딩 중...</p>
        ) : (
          <>
            <MeasureRecordResultTopInfo
              startDt={data!.startDt}
              endDt={data!.endDt}
              location={data!.location}
              simpleMeasureResultId={data!.simpleMeasureResultId}
            />
            <MeasureRecordResultTotalCount resultData={parsedData ?? []} />
            <MeasureRecordResultSummary resultData={parsedData ?? []} />
            <MeasureRecordResultChart
              resultData={parsedData ?? []}
              audioPath={data.recordingFileUrl ?? ""}
            />
          </>
        )}
      </ResultWrapper>
    </MeasureResultContainer>
  );
};

export default MeasureRecordResultDetail;
