import { fetchPreviewReviewComment } from "api/review";
import { alramClickedState } from "atoms/alram.state";
import { previewHeaderLoginModalState } from "atoms/layout.state";
import { reviewComentDeleteConfirmModalState } from "atoms/modal.state";
import { MoreIcon, RepleIcon } from "cssReset/icon";
import { ReviewComment } from "interface/interface.review";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { CommentBox, CommentContainer } from "style/review";
import useScrollOrDragUp from "utils/hook/ scroll.detection";
import { formatDateTime } from "utils/util";

interface ReviewCommentBoxProps {
  reviewId: string | undefined;
  regUserId: string | undefined;
  setCommentParentId: React.Dispatch<React.SetStateAction<number | null>>;
  focusInput: () => void; // input에 포커스
  updateTrigger: boolean;
}

const PreviewReviewComment = (props: ReviewCommentBoxProps) => {
  const [data, setData] = useState<ReviewComment[]>([]);
  useRecoilState(reviewComentDeleteConfirmModalState);
  const [deleteUpdateTrigger, setDeleteUpdateTrigger] = useState(false);

  const userId = localStorage.getItem("userId");
  const [searchParams] = useSearchParams();
  const moveTargetId = searchParams.get("targetId") || "";

  const setLoginModal = useSetRecoilState(previewHeaderLoginModalState);

  // 알림으로 페이지 이동해 오면 아래 상태값 필요.
  const alramLinkState = useRecoilValue(alramClickedState);
  const commentRefs = useRef<(HTMLDivElement | null)[]>([]); // 댓글 참조를 위한 배열

  useEffect(() => {
    const reviewCommentFetch = async () => {
      try {
        const response = await fetchPreviewReviewComment(
          Number(props.reviewId)
        );
        setData(response.result);
      } catch (err) {
        console.log(err);
      }
    };

    reviewCommentFetch();
  }, [props, props.updateTrigger, deleteUpdateTrigger]);

  // 푸쉬 클릭이로 이동해오면
  useEffect(() => {
    if (moveTargetId) {
      handleScrollToComment(Number(moveTargetId));
    }
  }, [moveTargetId]);

  // 알림 클릭으로 이동해오면 해당 함수 실행
  useEffect(() => {
    if (alramLinkState.linkYn && alramLinkState.commentId) {
      handleScrollToComment(alramLinkState.commentId);
    }
  }, [alramLinkState]);

  // 알림 클릭으로 이동해오면 코멘트 아이디 찾아서 그 위치로 이동
  const handleScrollToComment = (targetId: number) => {
    setTimeout(() => {
      const commentElement = commentRefs.current[targetId]; // 선택된 댓글 참조

      if (commentElement) {
        commentElement.scrollIntoView({
          behavior: "smooth", // 부드러운 스크롤 효과
          block: "center", // 댓글이 화면의 중앙에 오도록 설정
        });
      }
    }, 100);
  };

  const repleCount = () => {
    // 🔥 부모 댓글 중 useYn === "Y"인 데이터만 필터링
    // 부모 댓글 중 삭제된 댓글은 화면에는 보이지만 카운트 안함.
    const filteredData = data.filter((item) => item.useYn === "Y");

    if (filteredData.length > 0) {
      // 🔥 부모 댓글 개수
      const repleCount = filteredData.length;

      // 🔥 `children` 배열에서도 useYn === "Y"인 것들만 필터링하여 개수 추출
      const repleRepleCount = data.map(
        (item) =>
          item.children?.filter((child) => child.useYn === "Y").length || 0
      );

      // 🔥 배열의 모든 값을 합산
      const totalRepleCount = repleRepleCount.reduce(
        (acc, curr) => acc + curr,
        0
      );

      return totalRepleCount + repleCount; // 🔥 부모 + 자식 합산 댓글 개수 반환
    }

    return 0; // 🔥 `useYn === "Y"`인 데이터가 없으면 0 반환
  };

  useScrollOrDragUp();

  return (
    <CommentContainer>
      <div className="count-box">
        <span>댓글</span>
        <span className="count">{repleCount()}</span>
      </div>
      {data && data.length > 0 ? (
        <CommentBox>
          {data
            .slice()
            .reverse()
            .map((parent) => {
              // 🔹 부모 댓글이 삭제된 경우 (useYn !== "Y")
              const isDeleted = parent.useYn !== "Y";
              // 자식이 없을때
              const hasChildren = parent.children && parent.children.length > 0;
              const isBlockedUser = parent.blockedUserYn === "Y";
              const isReported = parent.hideYn === "Y"; // 관리자가 댓글 숨김 처리
              const isAccountDelete = parent.userUseYn === "Y";
              console.log(parent);

              // 🔹 삭제된 댓글이지만 대댓글이 있는 경우 댓글 박스 유지
              if (isDeleted && !hasChildren) return null;

              // 🔹 댓글 내용을 렌더링하는 함수
              const renderCommentContent = () => {
                switch (true) {
                  case isDeleted:
                    return (
                      <div className="comment-text-box">
                        <p className="delete-comment-txt">삭제된 댓글입니다.</p>
                      </div>
                    );

                  case isBlockedUser:
                    return (
                      <div className="comment-text-box blocked">
                        <span>차단한 사용자</span>의 댓글입니다.
                      </div>
                    );

                  case isReported:
                    return (
                      <div className="comment-text-box blocked">
                        <span>운영 정책 위반</span>으로 숨겨진 댓글입니다.
                      </div>
                    );

                  default:
                    return (
                      <div className="comment-text-box">{parent.contents}</div>
                    );
                }
              };
              return (
                <div
                  className="inner"
                  key={parent.reviewCommentId}
                  // 알림 클릭해서 위치로 이동하면 어디 댓글인지 참조
                  ref={(el) =>
                    (commentRefs.current[parent.reviewCommentId] = el)
                  } // 각 댓글에 ref 추가
                >
                  {/* 최상위 댓글 */}
                  <div className="parent-box">
                    <div className="profile-box">
                      {isAccountDelete ? (
                        !isBlockedUser ? (
                          parent.userProfileUrl ? (
                            <div>
                              <img src={parent.userProfileUrl} alt="" />
                            </div>
                          ) : (
                            <div>
                              <img src="/assets/icon/userprofile.png" alt="" />
                            </div>
                          )
                        ) : (
                          <div className="blocked-user-profile-img">
                            <span></span>
                          </div>
                        )
                      ) : (
                        <div className="blocked-user-profile-img">
                          <span></span>
                        </div>
                      )}
                    </div>
                    <div className="contents-box">
                      <div className="user-info-box">
                        <div className="wrapper">
                          {/* 차단 당한 사람의 profile */}
                          {isAccountDelete ? (
                            !isBlockedUser ? (
                              <div className="user-name">
                                {parent.userName}{" "}
                                {parent.regUserId === props.regUserId && (
                                  <span className="reg-user">작성자</span>
                                )}
                              </div>
                            ) : (
                              <div className="user-name blocked">
                                차단한 사용자{" "}
                                {parent.regUserId === props.regUserId && (
                                  <span className="reg-user">작성자</span>
                                )}
                              </div>
                            )
                          ) : (
                            <div className="user-name blocked">
                              탈퇴한 사용자{" "}
                              {parent.regUserId === props.regUserId && (
                                <span className="reg-user">작성자</span>
                              )}
                            </div>
                          )}
                          <span className="date">
                            {formatDateTime(parent.regDt)}
                          </span>
                        </div>
                        {!isDeleted && (
                          <div className="btn-box">
                            {userId === parent.regUserId ? (
                              <button
                                onClick={() => {
                                  setLoginModal({
                                    isOpen: true,
                                    isVisible: true,
                                  });
                                }}
                              >
                                <MoreIcon />
                              </button>
                            ) : (
                              // 차단 당하면 버튼 안보임
                              !isBlockedUser && (
                                <button
                                  onClick={() => {
                                    setLoginModal({
                                      isOpen: true,
                                      isVisible: true,
                                    });
                                  }}
                                >
                                  <MoreIcon />
                                </button>
                              )
                            )}
                          </div>
                        )}
                      </div>
                      {/* 차단 당한 사람의 글 */}
                      {renderCommentContent()}
                      <div className="reple-btn-box">
                        <button
                          onClick={() => {
                            setLoginModal({
                              isOpen: true,
                              isVisible: true,
                            });
                          }}
                        >
                          <span className="icon-box">
                            <RepleIcon />
                          </span>
                          답글{" "}
                          <span>
                            {parent.children?.filter(
                              (child) => child.useYn === "Y"
                            ).length || ""}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* 댓글에 댓글 */}
                  {parent.children && parent.children.length > 0 && (
                    <div>
                      {parent.children
                        .slice()
                        .reverse()
                        .map((child) => {
                          const isAccountDelete = parent.userUseYn === "Y";
                          const isChildDeleted = child.useYn !== "Y";
                          const isChildBlockedUser =
                            child.blockedUserYn === "Y";
                          const isReported = child.hideYn === "Y"; // 관리자가 댓글 숨김 처리

                          // 자식을 삭제하면 화면에 표시하지 않음.
                          if (isChildDeleted) return null;

                          // 🔹 댓글 내용을 렌더링하는 함수
                          const renderCommentContent = () => {
                            switch (true) {
                              case isChildBlockedUser:
                                return (
                                  <div className="comment-text-box blocked">
                                    <span>차단한 사용자</span>의 댓글입니다.
                                  </div>
                                );

                              case isReported:
                                return (
                                  <div className="comment-text-box blocked">
                                    <span>운영 정책 위반</span>으로 숨겨진
                                    댓글입니다.
                                  </div>
                                );

                              default:
                                return (
                                  <div className="comment-text-box">
                                    {child.contents}
                                  </div>
                                );
                            }
                          };
                          return (
                            <div
                              className="child-box"
                              key={child.reviewCommentId}
                              // 알림 클릭해서 위치로 이동하면 어디 댓글인지 참조
                              ref={(el) =>
                                (commentRefs.current[child.reviewCommentId] =
                                  el)
                              } // 각 댓글에 ref 추가
                            >
                              <div className="profile-box">
                                {isAccountDelete ? (
                                  !isChildBlockedUser ? (
                                    child.userProfileUrl ? (
                                      <div>
                                        <img
                                          src={
                                            child.userProfileUrl || undefined
                                          }
                                          alt=""
                                        />
                                      </div>
                                    ) : (
                                      <div>
                                        <img
                                          src="/assets/icon/userprofile.png"
                                          alt=""
                                        />
                                      </div>
                                    )
                                  ) : (
                                    <div className="blocked-user-profile-img">
                                      <span></span>
                                    </div>
                                  )
                                ) : (
                                  <div className="blocked-user-profile-img">
                                    <span></span>
                                  </div>
                                )}
                              </div>
                              <div className="contents-box">
                                <div className="user-info-box">
                                  <div className="wrapper">
                                    {isAccountDelete ? (
                                      !isChildBlockedUser ? (
                                        <div className="user-name">
                                          {child.userName}{" "}
                                          {child.regUserId ===
                                            props.regUserId && (
                                            <span className="reg-user">
                                              작성자
                                            </span>
                                          )}
                                        </div>
                                      ) : (
                                        <div className="user-name blocked">
                                          차단한 사용자{" "}
                                          {child.regUserId ===
                                            props.regUserId && (
                                            <span className="reg-user">
                                              작성자
                                            </span>
                                          )}
                                        </div>
                                      )
                                    ) : (
                                      <div className="user-name blocked">
                                        탈퇴한 사용자{" "}
                                        {child.regUserId ===
                                          props.regUserId && (
                                          <span className="reg-user">
                                            작성자
                                          </span>
                                        )}
                                      </div>
                                    )}
                                    <span className="date">
                                      {formatDateTime(child.regDt)}
                                    </span>
                                  </div>
                                  {/* 자식의 삭제 여부와 상관없이 화면에 표시 */}
                                  {!isChildDeleted && (
                                    <div className="btn-box">
                                      {userId === child.regUserId ? (
                                        <button
                                          onClick={() => {
                                            setLoginModal({
                                              isOpen: true,
                                              isVisible: true,
                                            });
                                          }}
                                        >
                                          <MoreIcon />
                                        </button>
                                      ) : (
                                        !isBlockedUser && (
                                          <button
                                            onClick={() => {
                                              setLoginModal({
                                                isOpen: true,
                                                isVisible: true,
                                              });
                                            }}
                                          >
                                            <MoreIcon />
                                          </button>
                                        )
                                      )}
                                    </div>
                                  )}
                                </div>
                                {renderCommentContent()}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              );
            })}
        </CommentBox>
      ) : (
        <div>
          <span>댓글을 남겨보세요.</span>
        </div>
      )}
    </CommentContainer>
  );
};

export default PreviewReviewComment;
