import { measureModalResultCompleteState } from "atoms/measure.state";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { BoxInner, TextBox } from "style/modal";
import { formatDateTimeAddSecUDTVer } from "utils/util";

interface ModalProps {
  endDt?: string;
}

const ModalMeasureResultComplete = ({ endDt }: ModalProps) => {
  const navigate = useNavigate();
  const setMeasureResultModal = useSetRecoilState(
    measureModalResultCompleteState
  );
  return (
    <BoxInner>
      <TextBox>
        <p>측정이 종료되었습니다.</p>
        <span>{`측정 종료 시간 \n ${formatDateTimeAddSecUDTVer(
          endDt ?? ""
        )}`}</span>
      </TextBox>
      <div className="column-btn-box">
        <button
          className="continue"
          onClick={() => {
            setMeasureResultModal({ isOpen: false, isVisible: false });
            navigate("/measure/simple/recording/result");
          }}
        >
          결과 보러가기
        </button>
        <button
          className="del"
          onClick={() => {
            setMeasureResultModal({ isOpen: false, isVisible: false });
            navigate("/measure/simple/recording/result/list");
          }}
        >
          측정 결과 목록 보기
        </button>
      </div>
    </BoxInner>
  );
};

export default ModalMeasureResultComplete;
