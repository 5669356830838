import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  height: 100vh;
`;

export const ReviewRegistContainer = styled.div`
  padding: 2.4rem 1.6rem;
  .title-box {
    margin-bottom: 2.4rem;
    textarea {
      border: 0;
      outline: 0;
      resize: none;
      width: 100%;
      padding: 0.8rem 0;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 2.4rem;
      max-height: 6rem; /* 최대 2줄 */
      height: 4rem;
      overflow: hidden; /* 넘치는 텍스트 감추기 */
      font-family: "Pretendard";
    }
  }
  .contents-box {
    textarea {
      border: 0;
      outline: 0;
      resize: none;
      width: 100%;
      min-height: 30rem;
      padding: 0.8rem 0;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
    }
  }
`;

export const ReviewDetailContainer = styled.div`
  min-height: calc(100vh - 5.6rem);
  @media screen and (min-width: 785px) {
    padding-bottom: 7.5rem;
  }
`;

export const UserInfoBox = styled.div`
  padding: 0.8rem 1.6rem;
  display: grid;
  grid-template-columns: 4rem auto;
  gap: 0.8rem;
  .profile-img {
    img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
      border: 0;
      outline: 0;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    justify-content: center;
    .user-name {
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 1.8rem;
      letter-spacing: -0.06rem;
    }
    .date {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
    }
  }
`;

export const ContentsBox = styled.div`
  padding: 1.6rem;
  background-color: #fafafa;
  .title {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2rem;
    letter-spacing: -0.06rem;
    margin-bottom: 1rem;
  }
  .contents-text {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
    white-space: pre-wrap;
  }
`;

export const SliderWrapper = styled.div`
  margin-top: 2.4rem;
  position: relative;
  .inner {
    padding: 0 1.4rem;
    .img-box {
      width: 31.5rem;
      height: 19.3rem;
      img {
        height: 100%;
        object-fit: contain;
        border-radius: 0.5rem;
        overflow: hidden;
        max-width: 100%;
        margin: 0 auto;
      }
    }
    .slick-arrow-custom {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      &.slick-arrow-left {
        left: -1.4rem;
      }
      &.slick-arrow-right {
        right: -1.4rem;
      }
    }
  }
  .slide-counter {
    position: absolute;
    bottom: 0.4rem;
    right: 2rem;
    padding: 0.2rem 0.4rem;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 10rem;
    font-weight: 400;
    line-height: 1.2rem;
    font-size: 1rem;
  }
`;

export const SubContentsBox = styled.div`
  display: grid;
  grid-template-columns: auto 2.4rem min-content;
  gap: 1rem;
  margin-top: 2.4rem;
  .review-count {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: #000;
    gap: 0.4rem;
    font-weight: 400;
    line-height: 1.6rem;
  }
  > .icon-box {
    display: flex;
    transition: all 0.2s;
    width: 100%;
    height: 100%;
    &.animate-icon {
      transform: scale(1.3); /* 하트 아이콘이 커졌다 작아지는 효과 */
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .like-box {
    display: flex;
    align-items: center;
    border: 1px solid #a1a1a1;
    gap: 0.4rem;
    padding: 0.3rem 0.8rem;
    border-radius: 1rem;
    .icon-box {
      display: flex;
      width: 1.6rem;
      height: 1.6rem;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .like-count {
      font-size: 1.2rem;
      line-height: 1.6rem;
      font-weight: 400;
      color: #a1a1a1;
      transition: all 0.3s;
      white-space: nowrap;
      &.animate-like {
        /* color: #ff0000; 숫자 색상이 빨간색으로 변경 */
      }
    }
    &.liked {
      border: 1px solid #e3a784;
      .like-count {
        color: #e3a784;
      }
    }
  }
`;
export const ListBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  .top-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .best-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;
      background-color: white;
      border: 1px solid #225298;
      border-radius: 10rem;
      color: #225298;
      padding: 0.3rem 0.8rem;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
      &.isActive {
        background-color: #225298;
        color: white;
      }
    }
    .order-by-box {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
      color: #242629;
      border: none;
      outline: none;
      background-color: white;
      padding: 0.8rem 0.4rem;
      .icon-box {
        width: 1.6rem;
        height: 1.6rem;
        display: flex;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .list-wrap {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    .review-item {
      padding: 0.8rem 0;
      display: flex;
      gap: 0.8rem;
      border-bottom: 1px solid #ececec;
      .l-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        .title {
          font-size: 1.4rem;
          font-weight: 700;
          line-height: 1.8rem;
          letter-spacing: -0.06rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; /* 말줄임 적용 */
          width: 20.4rem;
        }
        .contents {
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 1.6rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          width: 20.4rem;
        }
        .profile-box {
          display: flex;
          align-items: center;
          gap: 0.4rem;
          .username {
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.4rem;
          }
          .profile-img-box {
            width: 2rem;
            height: 2rem;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        .sub-items {
          display: flex;
          align-items: center;
          gap: 0.8rem;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.2rem;
          > span {
            display: flex;
            align-items: center;
            gap: 0.2rem;
            color: #a1a1a1;
            .icon-box {
              display: flex;
            }
          }
        }
      }
      .r-box {
        width: 7.2rem;
        height: 7.2rem;
        overflow: hidden;
        position: relative;
        border-radius: 1rem;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .img-count-box {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          padding: 0.4rem 0.8rem;
          color: white;
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 1.6rem;
        }
      }
    }
  }
`;

export const CommentContainer = styled.div`
  padding: 1.6rem;
  .count-box {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.8rem;
    display: flex;
    gap: 0.4rem;
    align-items: center;
    .count {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
    }
  }
`;

export const CommentBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-top: 1.6rem;
  .inner {
    .parent-box {
      display: grid;
      gap: 0.8rem;
      grid-template-columns: 3rem auto;
      align-items: flex-start;
    }
    .child-box {
      display: grid;
      gap: 0.8rem;
      grid-template-columns: 3rem auto;
      align-items: flex-start;
      margin-top: 1.6rem;
      padding-left: 4rem;
    }
    .profile-box {
      img {
        width: 3rem;
        height: 3rem;
        object-fit: cover;
      }
      .blocked-user-profile-img {
        display: flex;
        span {
          display: flex;
          width: 3rem;
          height: 3rem;
          border-radius: 3rem;
          background-color: #a1a1a1;
        }
      }
    }
    .contents-box {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
      .user-info-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .wrapper {
          display: flex;
          flex-direction: column;
          gap: 0.4rem;
          .user-name {
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.4rem;
            color: #000;
            display: flex;
            gap: 0.8rem;
            align-items: center;
            .reg-user {
              background-color: #a1a1a1;
              border-radius: 0.5rem;
              font-size: 0.8rem;
              font-weight: 400;
              line-height: 1.2rem;
              color: white;
              display: flex;
              padding: 0.2rem 0.4rem;
            }
            &.blocked {
              color: #a1a1a1;
            }
          }
          .date {
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.2rem;
            color: #575757;
          }
        }
        .btn-box {
          display: flex;
          button {
            border: 0;
            outline: 0;
            background-color: transparent;
            display: flex;
            padding: 0;
          }
        }
      }
      .comment-text-box {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.6rem;
        color: #000;
        white-space: pre-wrap;
        .delete-comment-txt {
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 1.6rem;
          color: #a1a1a1;
        }
        &.blocked {
          color: #a1a1a1;
          span {
            font-weight: 700;
          }
        }
      }
      .reple-btn-box {
        button {
          border: 0;
          outline: 0;
          background-color: transparent;
          display: flex;
          align-items: center;
          padding: 0;
          color: #575757;
          font-size: 1.2rem;
          line-height: 1.6rem;
          font-weight: 400;
          gap: 0.4rem;
          .icon-box {
            display: flex;
          }
        }
      }
    }
  }
`;

export const NoData = styled.div`
  padding: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 1.4rem;
    line-height: 2rem;
    padding: 1.6rem;
  }
`;
