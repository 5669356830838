import { AnimatePresence, motion } from "framer-motion";
import { UserInfoDt } from "interface/interface.profile";
import { useEffect, useState, useRef } from "react";
import {
  ComunityListsBox,
  ComunityListsContainer,
  ComunityListsMenu,
  ListStepMenuBox,
  ProfileBox,
} from "style/mypage";
import ComunityListItem from "./lists/list.item";
import {
  fetchLoadLikeMyReview,
  fetchLoadMyReview,
  fetchLoadMyReviewComment,
} from "api/mypage";
import ListItemAlram from "./lists/list.item.alram";

interface Props {
  profileDt: UserInfoDt;
}

const ListsWrapper = ({ profileDt }: Props) => {
  const [listStep, setListStep] = useState(0);
  const [data, setData] = useState({
    myReviews: [],
    myReviewComments: [],
    likedReviews: [],
    myNotifications: [], // ✅ "내글 소식"을 위한 초기 상태 추가
  });
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // ✅ 더 가져올 데이터가 있는지 확인

  const observer = useRef<IntersectionObserver | null>(null);
  const lastElementRef = useRef<HTMLDivElement | null>(null); // 마지막 요소 감지

  const menuHandler = (idx: number) => {
    setListStep(idx);
    setPage(1); // ✅ 새로운 메뉴 클릭 시 페이지 초기화
    setHasMore(true);
  };

  useEffect(() => {
    const loadData = async (reset = false) => {
      if (isLoading || !hasMore) return;
      setIsLoading(true);

      const params = {
        orderBy: "REGDT",
        orderByDir: "DESC",
        pageSize: 10,
        offset: reset ? 0 : (page - 1) * 10, // ✅ reset이면 offset도 0으로 초기화
      };

      try {
        let response: any = { result: [] }; // ✅ 기본값을 빈 배열로 설정

        if (listStep === 0) response = await fetchLoadMyReview(params);
        else if (listStep === 1)
          response = await fetchLoadMyReviewComment(params);
        else if (listStep === 2) response = await fetchLoadLikeMyReview(params);
        else if (listStep === 3) response = { result: [] }; // ✅ "내글 소식" 처리

        if (!response || !response.result) {
          setHasMore(false); // ✅ 응답이 없으면 데이터 없음 처리
          return;
        }

        if (response.result.length === 0) {
          setHasMore(false); // ✅ 더 이상 가져올 데이터 없음
        } else {
          setData((prevData) => {
            const key = listStepToKey(listStep);
            const newData = reset
              ? response.result
              : [...prevData[key], ...response.result];

            return {
              ...prevData,
              [key]: Array.from(new Set(newData.map((item: any) => item.id))) // 중복 제거
                .map((id) => newData.find((item: any) => item.id === id)),
            };
          });
        }
      } catch (error) {
        console.error("데이터 로딩 실패:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadData(true); // ✅ 초기 로딩 시 데이터 리셋
  }, [listStep]);

  // 무한 스크롤 감지 (Intersection Observer)
  useEffect(() => {
    if (isLoading || !hasMore) return;

    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 1.0 }
    );

    if (lastElementRef.current) {
      observer.current.observe(lastElementRef.current);
    }

    return () => observer.current?.disconnect();
  }, [isLoading, hasMore]);

  // ✅ "내글 소식" (listStep === 3) 처리 추가
  const listStepToKey = (step: number) => {
    switch (step) {
      case 0:
        return "myReviews";
      case 1:
        return "myReviewComments";
      case 2:
        return "likedReviews";
      case 3:
        return "myNotifications"; // ✅ "내글 소식"을 위한 키 추가
      default:
        return "myReviews";
    }
  };

  return (
    <ComunityListsContainer>
      <ProfileBox>
        <div className="img-box">
          {profileDt.files && profileDt.files.length > 0 ? (
            <img src={profileDt.files[0].accessUrl} alt="" />
          ) : (
            <img src="/assets/icon/userprofile.png" alt="" />
          )}
        </div>
        <div className="user-name">{profileDt.userName}</div>
      </ProfileBox>

      {/* 메뉴 */}
      <ComunityListsMenu>
        {["내가 쓴 글", "댓글단 글", "좋아요한 글", "내글 소식"].map(
          (listMenu, index) => (
            <ListStepMenuBox
              key={index}
              onClick={() => menuHandler(index)}
              className={`menu-item ${listStep === index ? "isActive" : ""}`}
              layout
              transition={{ type: "spring", stiffness: 300, damping: 20 }}
            >
              <motion.span transition={{ duration: 0.3 }}>
                {listMenu}
              </motion.span>

              {listStep === index && (
                <motion.div
                  layoutId="listUnderline_comunity"
                  className="underline"
                  transition={{ type: "spring", stiffness: 300, damping: 20 }}
                />
              )}
            </ListStepMenuBox>
          )
        )}
      </ComunityListsMenu>

      {/* 리스트 */}
      <ComunityListsBox>
        <AnimatePresence mode="wait" initial={false}>
          {listStep === 0 && <ComunityListItem key={0} data={data.myReviews} />}
          {listStep === 1 && (
            <ComunityListItem key={1} data={data.myReviewComments} />
          )}
          {listStep === 2 && (
            <ComunityListItem key={2} data={data.likedReviews} />
          )}
          {listStep === 3 && <ListItemAlram />}
        </AnimatePresence>

        {/* 무한 스크롤 트리거 */}
        {hasMore && !isLoading && (
          <div ref={lastElementRef} style={{ height: "10px" }} />
        )}
      </ComunityListsBox>
    </ComunityListsContainer>
  );
};

export default ListsWrapper;
