import { previewHeaderLoginModalState } from "atoms/layout.state";
import { useEffect, useRef } from "react";
import { useSetRecoilState } from "recoil";

const useScrollOrDragUp = () => {
  const lastYRef = useRef<number | null>(null);
  const setLoginModal = useSetRecoilState(previewHeaderLoginModalState);

  useEffect(() => {
    const handleScroll = () => {
      console.log("스크롤 발생!");
      setLoginModal({ isOpen: true, isVisible: true });
    };

    const handleTouchStart = (e: TouchEvent) => {
      lastYRef.current = e.touches[0].clientY;
    };

    const handleTouchMove = (e: TouchEvent) => {
      const currentY = e.touches[0].clientY;
      if (lastYRef.current !== null && currentY < lastYRef.current) {
        console.log("위로 드래그됨!"); // ✅ 이제 정확하게 위로 드래그일 때만 찍힘
        setLoginModal({ isOpen: true, isVisible: true });
      }
      lastYRef.current = currentY;
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("touchmove", handleTouchMove);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);
};

export default useScrollOrDragUp;
