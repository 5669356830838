import { fetchPreviewReviewList } from "api/review";
import { bestReviewTotalClickedState } from "atoms/main.state";
import { orderByStateModal } from "atoms/modal.state";
import ModalUpLayout from "components/modal/modal.up.layout";
import { DonwArrowIcon, HeartIconX14, RepleIcon } from "cssReset/icon";
import { ReviewItem } from "interface/interface.review";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { ListBox, NoData } from "style/review";
import { formatDateTime } from "utils/util";
import ModalReviewListOrderByModal from "view/review/children/modal/modal.review.list.order.by.modal";

const PreviewReviewList = () => {
  const [reviewData, setReviewData] = useState<ReviewItem[]>([]);
  const [onlyBest, setOnlyBest] = useState(false);
  const [orderByState, setOrderByState] = useState("REGDT");

  // infinity scroll
  const [page, setPage] = useState(1); // ✅ 페이지 상태 추가
  const [isLoading, setIsLoading] = useState(false); // ✅ 로딩 상태 추가
  const [hasMore, setHasMore] = useState(true);
  const observerRef = useRef<HTMLDivElement | null>(null); // ✅ Intersection Observer를 위한 ref 생성

  // order by recoil state
  const [orderByModalState, setOrderByModalState] =
    useRecoilState(orderByStateModal);
  const bestReviewClickedState = useRecoilValue(bestReviewTotalClickedState);

  const navigate = useNavigate();

  // ✅ 데이터 가져오기 함수
  const reviewFetchHandler = useCallback(
    async (reset = false) => {
      if (isLoading || (!hasMore && !reset)) return; // 중복 호출 방지

      setIsLoading(true);

      try {
        const params = {
          onlyBest: onlyBest,
          orderBy: orderByState,
          orderByDir: orderByState === "LIKES" ? "DESC" : "DESC",
          pageSize: 10,
          offset: reset ? 0 : (page - 1) * 10, // ✅ reset이면 offset도 0으로 초기화
        };
        const response = await fetchPreviewReviewList(params);
        if (!response.result || response.result.length === 0) {
          setHasMore(false); // ✅ 더 이상 데이터가 없으면 요청 중단
        } else {
          setReviewData((prev) => {
            if (reset) return response.result; // ✅ 초기화 시 새로운 데이터만 반영
            const uniqueData = new Map(
              [...prev, ...response.result].map((item) => [item.reviewId, item])
            );
            return Array.from(uniqueData.values());
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    },
    [onlyBest, orderByState, page, isLoading, hasMore]
  );

  // ✅ 필터 변경 시 데이터 초기화 + 강제 fetch 실행
  useEffect(() => {
    setReviewData([]); // ✅ 기존 데이터 초기화
    setPage(1); // ✅ 페이지 초기화
    setHasMore(true); // ✅ 새로운 데이터 요청 가능하도록 설정
    reviewFetchHandler(true); // ✅ 즉시 실행 (reset = true)
  }, [onlyBest, orderByState]); // ✅ onlyBest, orderByState 변경 시 실행

  // ✅ 페이지 변경 시 실행 (무한 스크롤)
  useEffect(() => {
    if (page !== 1) {
      reviewFetchHandler();
    }
  }, [page]);

  // ✅ Intersection Observer 적용
  useEffect(() => {
    if (!observerRef.current || !hasMore) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isLoading) {
          setPage((prev) => prev + 1);
        }
      },
      { threshold: 1.0 }
    );

    observer.observe(observerRef.current);

    return () => observer.disconnect();
  }, [isLoading, hasMore]);

  useEffect(() => {
    setTimeout(() => {
      if (bestReviewClickedState) {
        setOnlyBest(true);
      }
      setIsLoading(false); // 로딩이 완료되면 isLoading을 false로 설정
    }, 500); // 100ms 딜레이 후 로딩 종료
  }, [bestReviewClickedState]);

  // 베스트 후기 필터 변경
  const bestClickHandler = () => {
    setOnlyBest((prev) => !prev);
  };

  // 정렬 변경
  const orderByClickHandler = (orderBy: string) => {
    setOrderByState(orderBy);
  };

  // 후기 상세 페이지 이동
  const reviewDetailEnter = (id: string) => {
    navigate(`/preview/review/detail/${id}`);
  };

  // order by modal function
  const handleOrderByModalOpen = () => {
    setOrderByModalState({ isOpen: true, isVisible: true });
  };
  const handleOrderByModalClosed = () => {
    setOrderByModalState({ isOpen: false, isVisible: false });
  };

  return (
    <ListBox>
      <div className="top-view">
        <button
          onClick={bestClickHandler}
          className={`best-btn ${onlyBest ? "isActive" : ""}`}
        >
          베스트 후기
        </button>
        <button onClick={handleOrderByModalOpen} className="order-by-box">
          <span className="icon-box">
            <DonwArrowIcon />
          </span>{" "}
          {orderByState === "REGDT" ? "최신순" : "좋아요순"}
        </button>
      </div>
      <div className="list-wrap">
        {reviewData.length > 0 ? (
          reviewData.map((item, index) => (
            <div
              key={`${item.reviewId}-${index}`} // ✅ reviewId + index로 고유한 key 생성
              className="review-item"
              onClick={() => reviewDetailEnter(String(item.reviewId))}
              ref={index === reviewData.length - 1 ? observerRef : null} // ✅ 마지막 아이템 ref 할당
            >
              <div className="l-box">
                <p className="title">{item.title}</p>
                <p className="contents">{item.contents}</p>
                <div className="profile-box">
                  <div className="profile-img-box">
                    <img
                      src={
                        item.userProfileUrl
                          ? item.userProfileUrl
                          : "/assets/icon/userprofile.png"
                      }
                      alt="profile"
                    />
                  </div>
                  <span className="username">{item.userName}</span>
                </div>
                <div className="sub-items">
                  <span className="date">{formatDateTime(item.regDt)}</span>
                  <span className="like-count">
                    <span className="icon-box">
                      <HeartIconX14 />
                    </span>
                    {item.reviewLikeCount}
                  </span>
                  <span className="comment-count">
                    <span className="icon-box">
                      <RepleIcon />
                    </span>
                    {item.reviewCommentCount}
                  </span>
                </div>
              </div>
              {item.files.length > 0 && (
                <div className="r-box">
                  <div className="img-box">
                    <img
                      src={item.files?.[0]?.accessUrl}
                      alt={item.files?.[0]?.realFileName}
                    />
                  </div>
                  {item.files.length > 1 && (
                    <div className="img-count-box">+ {item.files.length} </div>
                  )}
                </div>
              )}
            </div>
          ))
        ) : (
          <NoData>
            <p className="no-data">후기가 없습니다.</p>
          </NoData>
        )}
      </div>
      {isLoading && <p className="loading">로딩 중...</p>}

      {orderByModalState.isOpen && (
        <ModalUpLayout
          closedHandler={handleOrderByModalClosed}
          isVisible={orderByModalState.isVisible}
          btnTitle="취소"
        >
          <ModalReviewListOrderByModal
            orderByState={orderByState}
            modalClosed={handleOrderByModalClosed}
            orderByClickHandler={orderByClickHandler}
          />
        </ModalUpLayout>
      )}
    </ListBox>
  );
};

export default PreviewReviewList;
