import styled from "styled-components";

export const BoxInner = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
  .btn-box {
    display: flex;
    position: relative;
    button {
      border: 0;
      outline: 0;
      flex: 1;
      background-color: transparent;
      padding: 1.8rem 0;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 2rem;
      letter-spacing: -0.06rem;
      color: #575757;
      &.del {
        color: #ed2115;
      }
    }
    .continue {
      color: #242629;
    }
    span {
      position: absolute;
      display: block;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1px;
      height: 2.4rem;
      background-color: #a1a1a1;
    }
  }
  .column-btn-box {
    padding: 1.6rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    button {
      border: none;
      outline: none;
      display: flex;
      border-radius: 0.5rem;
      align-items: center;
      justify-content: center;
      &.continue {
        padding: 1.6rem;
        background-color: #225298;
        color: white;
        font-size: 1.6rem;
        line-height: 2.4rem;
        font-weight: 700;
      }
      &.del {
        padding: 0.6rem;
        font-size: 1.4rem;
        line-height: 2rem;
        letter-spacing: -0.06rem;
        font-weight: 500;
        background-color: transparent;
      }
    }
  }
  .login-return-btn-box {
    padding: 1.6rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    button {
      border: none;
      outline: none;
      display: flex;
      border-radius: 100px;
      align-items: center;
      justify-content: center;
      &.continue {
        padding: 1.6rem;
        background-color: #225298;
        color: white;
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 700;
      }
      &.del {
        padding: 0.6rem;
        font-size: 1.4rem;
        line-height: 2rem;
        letter-spacing: -0.06rem;
        font-weight: 500;
        background-color: transparent;
      }
    }
  }
`;

export const TextBox = styled.div`
  display: flex;
  gap: 2.4rem;
  flex-direction: column;
  padding: 5.2rem 1.6rem 0;
  p {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2rem;
    letter-spacing: -0.06rem;
    text-align: center;
  }
  span {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
    text-align: center;
    display: block;
    color: #575757;
    white-space: pre-wrap;
  }
`;
