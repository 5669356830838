import { fetchAlramHandler } from "api/mypage";
import { allRemoveConfirmModalState } from "atoms/alram.state";
import AlramList from "components/alram/alram.list";
import ModalCenterLayout from "components/modal/modal.center.layout";
import { NotificationResult } from "interface/interface.alram";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { AlramContainer, AlramListWrap } from "style/alram";
import ModalAllRemove from "view/alram/children/modal/modal.all.remove";

const ListItemAlram = () => {
  const [alramDt, setAlramDt] = useState<NotificationResult[]>([]); // 알림 데이터를 배열로 초기화
  const [page, setPage] = useState(1); // 페이지 상태
  const [isLoading, setIsLoading] = useState(false); // 로딩 상태
  const [hasMore, setHasMore] = useState(true); // 더 많은 데이터가 있는지 확인
  const [updateTrigger, setUpdateTrigger] = useState(false); // 업데이트 트리거 추가

  const [allRemoveModalStatus, setAllRemoveModalStatus] = useRecoilState(
    allRemoveConfirmModalState
  );

  const loadAlramList = async () => {
    if (isLoading || !hasMore) return; // 중복 호출 방지 및 더 이상 데이터가 없으면 리턴

    setIsLoading(true);

    try {
      const params = {
        isRead: "N",
        orderBy: "REGDT",
        orderByDir: "DESC",
        pageSize: 10,
        offset: (page - 1) * 10, // 페이지에 맞는 데이터 로드
      };

      const response = await fetchAlramHandler(params);

      if (!response.result || response.result.length === 0) {
        setHasMore(false); // 더 이상 데이터가 없으면 hasMore을 false로 설정
      } else {
        // 기존 데이터와 새로운 데이터를 병합하지만 중복되지 않도록
        setAlramDt((prevAlrams) => {
          const newAlrams = response.result;
          const allAlrams = [...prevAlrams, ...newAlrams];
          const uniqueAlrams = Array.from(
            new Set(allAlrams.map((a) => a.notificationId))
          ).map((id) => allAlrams.find((a) => a.notificationId === id));
          return uniqueAlrams as NotificationResult[];
        });
        setPage((prevPage) => prevPage + 1); // 페이지 증가
      }
    } catch (error) {
      console.error("알림 로딩 중 에러:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setPage(1); // 페이지 초기화
    setHasMore(true); // 새로운 데이터가 존재한다고 가정
    setAlramDt([]); // 기존 데이터 초기화
    loadAlramList();
  }, [updateTrigger]);

  useEffect(() => {
    const handleScroll = () => {
      // 스크롤이 끝에 가까워지면
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100
      ) {
        loadAlramList(); // 추가 데이터 로드
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isLoading, hasMore]);

  const alramAllRemoveHandler = () => {
    handleAllRemoveModalOpened();
  };

  // all remove modal
  const handleAllRemoveModalClosed = () => {
    setAllRemoveModalStatus({ isOpen: false, isVisible: false });
  };
  const handleAllRemoveModalOpened = () => {
    setAllRemoveModalStatus({ isOpen: true, isVisible: true });
  };

  const triggerUpdate = () => {
    setUpdateTrigger((prev) => !prev); // 상태 변경으로 `useEffect` 재실행
  };

  return (
    <AlramContainer>
      {alramDt && alramDt?.length > 0 ? (
        <AlramListWrap>
          {alramDt.map((item, index) => (
            <AlramList alramDt={item} key={index} />
          ))}

          <div className="alrem-info">
            <p>30일이 지난 알림은 삭제됩니다.</p>
          </div>
        </AlramListWrap>
      ) : (
        <AlramListWrap>
          <div className="none-block-user">
            <p>도착한 알림이 없습니다.</p>
          </div>
        </AlramListWrap>
      )}

      {/* 전체 삭제 확인 모달 */}
      {allRemoveModalStatus.isOpen && (
        <ModalCenterLayout
          closedHandler={handleAllRemoveModalClosed}
          isVisible={allRemoveModalStatus.isVisible}
        >
          <ModalAllRemove
            closedHandler={handleAllRemoveModalClosed}
            triggerUpdate={triggerUpdate}
          />
        </ModalCenterLayout>
      )}
    </AlramContainer>
  );
};

export default ListItemAlram;
